import moment from 'moment'
import './plan.scss'
import React, {useContext, useEffect, useState} from 'react'
import { UserContext } from '../../context'
import currencyFormatter from 'currency-formatter'
import { ROUND_USD_CURRENCY_FORMAT } from '../../utils/DataFormatter'
import { useHistory } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { TopbarBackNavigation } from '../common/topbar'
import Button, { button_type, button_size } from '../common/button'
import InfoDataRow from '../common/infodatarow'
import { BasicFieldRow } from '../common/fieldrow'
import API from '../../api-service'

export default function AccountPlansPage(props) {
  const [user] = useContext(UserContext)
  const active_subscriptions = user['subscriptions_data']['active_subscriptions']
  const completed_subscriptions = user['subscriptions_data']['completed_subscriptions']

  return(
    <div>
      <div className="main_griffin_container main_griffin_container_extra_bottom_padding">
        <div className="text-2xl font-semibold pb-4">Plans</div>
        <div className="divide-y divide-content-quaternary">
          {active_subscriptions.map((subscription) => <SubscriptionRow key={subscription.id} subscription={subscription} />)}
        </div>
        {completed_subscriptions.length > 0 && 
          <div>
            <h5>Completed plans</h5>
            <div className="divide-y divide-content-quaternary">
              {completed_subscriptions.map((subscription) => <SubscriptionRow key={subscription.id} subscription={subscription} />)}
            </div>
          </div>
        }
      </div>
    </div>
  )
}

function SubscriptionRow(props) {
  const history = useHistory();
  const subscription_name = `${props.subscription.subscription_plan.display_name_v2} Plan #${props.subscription.id.toString().padStart(4, '0')}`;
  const activation_time = `${moment(props.subscription.start_at).format("MM/DD/YY")} - ${moment(props.subscription.end_at).format("MM/DD/YY")}`;

  return(
    <BasicFieldRow onClick={() => history.push('/plan/' + props.subscription.id.toString())}>
      <div className="flex-grow space-y-2">
        <p>{subscription_name}</p>
        <p className="text-sm text-content-secondary">{activation_time}</p>
      </div>
      <div className="text-right space-y-2">
        <p>${currencyFormatter.format(props.subscription.total_earning, 'USD')}</p>
        <p className="text-sm text-content-secondary">{currencyFormatter.format(props.subscription.guarantee_earning, ROUND_USD_CURRENCY_FORMAT)} guaranteed</p>
      </div>
    </BasicFieldRow>
  )
}

export function PlanDetailPage(props) {
  const [subscriptionDetail, setSubscriptionDetail] = useState(null)
  const [cookies, removeCookie] = useCookies(['griffin-token']);
  const history = useHistory();
  
  const subscription_id = props.match.params.subscription_id;

  useEffect(() => {
    if (cookies['griffin-token'] === undefined || cookies['griffin-token'] === 'undefined') return;

    API.getSubscriptionDetail(cookies['griffin-token'], subscription_id)
      .then(response => {
        if (response.status === 401) {
          removeCookie('griffin-token');
        } else if (response.ok) {
          response.json().then(subscription_detail_data => {
            setSubscriptionDetail(subscription_detail_data.data);
          });
        }
      })
  }, [cookies, removeCookie, subscription_id]);

  return(
    <div>
      <TopbarBackNavigation />
      {subscriptionDetail &&
        <div>
          <div className="main_griffin_container">
            <p className="text-content-secondary">{subscriptionDetail.subscription_plan.display_name_v2} Plan #{subscription_id.toString().padStart(4, '0')}</p>
            <p className="text-4xl pt-2 font-semibold">${currencyFormatter.format(subscriptionDetail.total_earning, 'USD')} <span className="text-lg font-normal">earned</span></p>
            <div className="pt-6">
              <InfoDataRow label="Plan period" info_data={`${moment(subscriptionDetail.start_at).format("MMM DD, YYYY")} - ${moment(subscriptionDetail.end_at).format("MMM DD, YYYY")}`}/>
              <InfoDataRow label="Guaranteed earnings" info_data={`${currencyFormatter.format(subscriptionDetail.guarantee_earning, ROUND_USD_CURRENCY_FORMAT)}`}/>
              {subscriptionDetail.subscription_payments.filter((payment) => payment.receipt_type === 'ACTIVATION').map((payment) => 
                <div className="py-4 border-t border-gray-100" key={payment.payment_receipt.id}>
                  <Button button_type={button_type.underline} button_size={button_size.small} onClick={() => history.push('/account/receipt/' + payment.payment_receipt.id)}>View receipt</Button>
                </div>
              )}
            </div>
          </div>
          {subscriptionDetail.subscription_payouts.length > 0 &&
            <div>
              <div className="-mt-6 border-t-8 border-gray-100 max-w-container ml-auto mr-auto"></div>
              <div className="main_griffin_container">
                <p className="pb-2 font-semibold">Payouts</p>
                {subscriptionDetail.subscription_payouts 
                  ? subscriptionDetail.subscription_payouts.map((payout) => <SubscriptionPayoutRow key={payout.id} payout={payout.payout} />)
                  : <p className="text-sm text-content-secondary">You have no available payout at this moment</p>
                }
              </div>
            </div>
          }
        </div>
      }
    </div>
  )
}

function SubscriptionPayoutRow(props) {
  return (
    <div className="flex subscription_payout_component_payout_container">
      <div className="flex-grow space-y-2">
        <p>Sent to {props.payout.payout_address_name}</p>
        <p className="text-sm text-content-secondary">{moment.unix(props.payout.payout_timestamp).format("MMM DD, YYYY")}</p>
      </div>
      <div className="text-right space-y-2">
        <p>${currencyFormatter.format(props.payout.fiat_amount, 'USD')}</p>
        <p className="text-sm text-content-secondary">{parseFloat(props.payout.amount).toFixed(5)} {props.payout.payout_currency}</p>
      </div>
    </div>
  )
}
