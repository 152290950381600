import moment from 'moment'
import React, {useEffect, useState} from 'react'
import currencyFormatter from 'currency-formatter'
import { useHistory } from 'react-router-dom'
import { ROUND_USD_CURRENCY_FORMAT } from '../../utils/DataFormatter'
import { useCookies } from 'react-cookie'
import { TopbarBackNavigation } from '../common/topbar'
import InfoDataRow from '../common/infodatarow'
import { BasicFieldRow } from '../common/fieldrow'
import API from '../../api-service'


export function AccountDocumentsPage(props) {
  const [receipts, setReceipts] = useState([])
  const [cookies, removeCookie] = useCookies(['griffin-token']);
  

  useEffect(() => {
    if (cookies['griffin-token'] === undefined || cookies['griffin-token'] === 'undefined') return;

    API.getPaymentReceipts(cookies['griffin-token'])
      .then(response => {
        if (response.status === 401) {
          removeCookie('griffin-token');
        } else if (response.ok) {
          response.json().then(receipts_data => {
            setReceipts(receipts_data.data);
          });
        }
      })
  }, [cookies, removeCookie]);

  return(
    <div>
      <div className="main_griffin_container">
        <div className="text-2xl font-semibold pb-4">Documents</div>
        {receipts.length > 0 &&
          <div className="py-6">
            <p className="text-xl font-semibold">Invoices</p>
            <div className="divide-y divide-content-quaternary">
              {receipts.map((receipt) => <ReceiptRow key={receipt.id} receipt={receipt} />)}
            </div>
          </div>
        }
      </div>
    </div>
  )
}

function ReceiptRow(props) {
  const history = useHistory();

  return(
    <BasicFieldRow onClick={() => history.push('/account/receipt/' + props.receipt.id.toString())}>
      <div className="flex-grow space-y-2">
        <p>{`Invoice #${props.receipt.id.toString().padStart(4, '0')}`}</p>
        <p className="text-sm text-content-secondary">{moment(props.receipt.payment_date).format("MMM DD, YYYY")}</p>
      </div>
      <p className="text-right">${currencyFormatter.format(props.receipt.amount, 'USD')}</p>
    </BasicFieldRow>
  )
}


export function ReceiptDetailPage(props) {
  const [receiptDetail, setReceiptDetail] = useState(null)
  const [cookies, removeCookie] = useCookies(['griffin-token']);
  
  const receipt_id = props.match.params.receipt_id;

  useEffect(() => {
    if (cookies['griffin-token'] === undefined || cookies['griffin-token'] === 'undefined') return;
    
    API.getPaymentReceiptDetail(cookies['griffin-token'], receipt_id)
      .then(response => {
        if (response.status === 401) {
          removeCookie('griffin-token');
        } else if (response.ok) {
          response.json().then(receipt_detail_data => {
            setReceiptDetail(receipt_detail_data.data);
          });
        }
      })
  }, [cookies, removeCookie, receipt_id]);

  return(
    <div>
      <TopbarBackNavigation />
      {receiptDetail &&
        <div>
          <div className="main_griffin_container">
            <div className="text-2xl font-semibold pb-4">Transaction Details</div>
            <div className="py-6">
              <p className="text-xl font-semibold">Payment details</p>
              <div className="pt-6">
                <InfoDataRow dotted_row={true} label="Account ID" info_data={receiptDetail.payment_receipts[0].subscription.subscription_owner}/>
                <InfoDataRow dotted_row={true} label="Transaction number" info_data={`#${receiptDetail.id.toString().padStart(4, '0')}`}/>
                <InfoDataRow dotted_row={true} label="Payment date" info_data={moment(receiptDetail.payment_date).format("MMM DD, YYYY")}/>
                <InfoDataRow dotted_row={true} label="Payment amount" info_data={currencyFormatter.format(receiptDetail.amount, ROUND_USD_CURRENCY_FORMAT)}/>
              </div>
            </div>
          </div>
          <div>
            <div className="-mt-6 border-t-8 border-gray-100 max-w-container ml-auto mr-auto"></div>
            <div className="main_griffin_container">
              <p className="pb-2 text-xl font-semibold">Subscription details</p>
              {receiptDetail.payment_receipts.map((subscription_receipt) => 
                <SubscriptionReceipt subscription_receipt={subscription_receipt} key={subscription_receipt.subscription.id}/>
              )}
            </div>
          </div>
        </div>
      }
    </div>
  )
}

function SubscriptionReceipt(props) {
  const subscription_id = props.subscription_receipt.subscription.id.toString().padStart(4, '0');
  const subscription_start = moment(props.subscription_receipt.subscription.start_at).format("MMM DD, YYYY");
  const subscription_end = props.subscription_receipt.receipt_type === "EXTENSION" 
    ? moment(props.subscription_receipt.subscription.end_at).add(1, 'y').format("MMM DD, YYYY")
    : moment(props.subscription_receipt.subscription.end_at).format("MMM DD, YYYY")
  const subscription_price = props.subscription_receipt.receipt_type === "ACTIVATION"
    ? props.subscription_receipt.subscription.subscription_plan.activation_cost + props.subscription_receipt.subscription.subscription_plan.yearly_cost
    : props.subscription_receipt.subscription.subscription_plan.yearly_cost;
  const subscription_guaranteed = subscription_price * (1 + props.subscription_receipt.subscription.subscription_plan.guarantee_earning_percentage/100);

  return (
    <div className="pt-8">
      <p className="font-semibold">Plan #{subscription_id}</p>
      <div className="pt-6">
        <InfoDataRow label="Subscription name" info_data={`${props.subscription_receipt.subscription.subscription_plan.display_name_v2} Plan`}/>
        { props.subscription_receipt.receipt_type === "ACTIVATION" &&
          <InfoDataRow label="Subscription period" info_data={`${subscription_start} - ${subscription_end}`}/>
        }
        { props.subscription_receipt.receipt_type === "EXTENSION" &&
          <InfoDataRow label="Estimated end date" info_data={`${subscription_end}`}/>
        }
        { props.subscription_receipt.receipt_type === "EXTENSION_COMPLETED" &&
          <InfoDataRow label="End date" info_data={`${subscription_end}`}/>
        }
        <InfoDataRow label="Guanranteed earnings" info_data={currencyFormatter.format(subscription_guaranteed, ROUND_USD_CURRENCY_FORMAT)}/>
        <InfoDataRow label="Price" info_data={currencyFormatter.format(subscription_price, ROUND_USD_CURRENCY_FORMAT)}/>
      </div>
    </div>
  )
}