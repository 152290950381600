import React from 'react'
import { useHistory } from 'react-router-dom'
import Heading from '../common/heading'
import TaxDisclosure from './tax-disclosure'
import Button, {button_type, button_size} from '../common/button';

export default function TaxCointracker(props) {

  const history = useHistory()

  function handleNavigateToTaxes(event) {
    history.push("/tax");
  }

  return (
    <div>
      <div className="main_griffin_container space-y-10">
        <div className='space-y-6'>
          <Heading text="CoinTracker" />
          <div className='space-y-10'>
            <div className='space-y-4'>
              <p>CoinTracker is offering Coinbase accounts free tax reports for up to 3000 transactions. Those reports include Form 8949, Schedule D, and Schedule 1.</p>
              <p>Here’s how to get them:</p>
              <p>1. Sign up for CoinTracker using your Coinbase account</p>
              <LinkPreviewTile
                url='https://www.cointracker.io/user/coinbase-sign-in'
                imgUrl='https://res.cloudinary.com/dqafyoayf/image/upload/v1644926456/taxGuide-CoinbaseAuthorize_ei2gu4.png'
                imgAlt='Cointracker'
                text='Coinbase - Authorize cointracker.io'/>
            </div>

            <div className='space-y-4'>
              <p>2. Give CoinTracker a few minutes to import your Coinbase transactions, then head over to <span className='font-semibold'>CoinTracker - 2021 Taxes</span>.</p>
              <LinkPreviewTile
                url='https://www.cointracker.io/tax/2021'
                imgUrl='https://s3-us-west-1.amazonaws.com/coin-tracker-public/static/images/social/cointracker.png'
                imgAlt='Cointracker 2021 taxes'
                text='CoinTracker - 2021 Taxes'/>
              <p>Once it finishes importing, you’ll see your <span className='font-semibold'>Total Capital Gains</span>.</p>
              <img alt='' src='https://res.cloudinary.com/dqafyoayf/image/upload/v1644926618/taxGuide-capitalGains_rx6zec.png' className='rounded-xl w-full'/>
            </div>

            <div className='space-y-4'>
              <p>3. Scroll down to <span className='font-semibold'>Tax Reports</span> box and download the Forms you need.</p>
              <img alt='' src='https://res.cloudinary.com/dqafyoayf/image/upload/v1644926619/taxGuide-taxReports_zv1bdi.png' className='rounded-xl w-full'/>
            </div>
            
          </div>
        </div>
        
        <div className='space-y-6'>
          <Button button_type={button_type.underline} button_size={button_size.small} onClick={handleNavigateToTaxes}>Back to Taxes</Button>
          <TaxDisclosure />
        </div>
        
      </div>
    </div>
  )
}

function LinkPreviewTile(props) {
  return(
    <a href={props.url} className='max-w-sm flex flex-col rounded-xl text-sm font-semibold ring-1 ring-content-tertiary hover:ring-2 hover:ring-content-primary hover:bg-gray-50 transition-all relative z-10'>
        <img className='w-full rounded-t-xl relative z-0' src={props.imgUrl} alt={props.imgAlt} />
        <div className='p-4 font-semibold underline'>
          {props.text} ↗
        </div>
    </a>
  )
}