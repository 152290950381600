import React from 'react'
import { GetRequestParam } from '../utils/request';
import ScrollToTop from './common/scrollToTop';
import { Link } from 'react-router-dom';
import { PreSignInFooter } from './common/footer';


export default function TermsScreen() {
  return (
    <ScrollToTop>
      <div className="container px-8 xl:px-24 py-10 lg:py-20">
        <Link to="/"><img alt="Griffin logo" className="w-8 lg:w-10 pb-10" src="https://res.cloudinary.com/dqafyoayf/image/upload/v1629697839/Griffin_Logomark_-_Red_kuseg9.svg"/></Link>
        <Terms privacy_link="/home/privacy"/>
        <PreSignInFooter />
      </div>
    </ScrollToTop>
  )
}

export function MobileTermsScreen(props) {
    const is_dark_mode = GetRequestParam("mode") === 'dark';
  
    return (
      <div className={is_dark_mode ? 'bg-black' : 'bg-white'}>
        <div className={`container px-4 py-2 ${is_dark_mode ? 'text-white' : 'text-black'}`}>
          <Terms privacy_link={`/privacy-mobile?mode=${is_dark_mode ? 'dark' : 'light'}`}/>
        </div>
      </div>
    )
}

export function TermsReferralScreen(props) {
    const is_dark_mode = GetRequestParam("mode") === 'dark';
  
    return (
      <div className={is_dark_mode ? 'bg-black' : 'bg-white'}>
        <div className={`container px-4 py-2 ${is_dark_mode ? 'text-white' : 'text-black'}`}>
          <Terms privacy_link={`/privacy-mobile?mode=${is_dark_mode ? 'dark' : 'light'}`}/>
        </div>
      </div>
    )
}

function Terms(props) {
    return (
        <div>
            <div className="text-2xl font-semibold pb-4">Terms of Service</div>
            <div>Thanks for using Griffin. Please read these Terms carefully.</div>
            <div>Updated: June 20th, 2022</div>

            <section className="pt-12 space-y-4">
                <div>
                    These Terms of Service (“Terms”, “Agreement”) are a binding legal agreement between you and Griffin Labs, Inc. (“Griffin”, “we”, “us”, or “our”) that govern your use of the websites, mobile applications and other offerings from Griffin (collectively, the “Griffin Platform” or the “Platform”).
                </div>
                <div>
                    The Griffin Platform offers an online service that enables users (“Customers") to: (i) purchase digital mining plans; (ii) store Digital Currency (to-do) in a custodial wallet. Customers must register an account to access and use many features of the Griffin Platform, and must keep your account information accurate.
                </div>
                <div>
                    We maintain other terms and policies that supplement these Terms like our <a className="font-semibold underline" href={props.privacy_link} title="privacy policy" rel="noreferrer">Privacy Policy</a>, which describes our collection and use of personal data, and <a className="font-semibold underline" href="https://www.sendwyre.com/legal/user-agreement" target="_blank" title="User agreement" rel="noreferrer">Wyre’s User Agreement</a>, which governs any custodial services and payment services provided to Customers by our partner, Wyre Payments, Inc. (“Wyre”) and their affiliates.
                </div>
                <div>
                    We may make updates to these Terms from time to time and will inform you of such change through email or the Griffin Platform. Changes will be effective immediately unless noted otherwise. If you do not agree to the updates and no longer wish to abide by these Terms, you can inform us and instruct us to close your account.
                </div>
                <div>
                    By using our Services, you acknowledge that you have read, understood, and accepted these Terms.
                </div>
            </section>

            <section className="pt-12 space-y-4">
                <div className="text-xl font-semibold">Acknowledgement of Risks</div>
                <div>
                    The use of Digital Currency may carry substantial financial risks. You should carefully consider whether mining or holding Digital Currency is suitable for your specific situation and financial condition. Griffin is not registered with the U.S. Securities and Exchange Commission and does not offer securities services in the United States or to U.S. persons. You acknowledge that Digital Currency is not subject to protections or insurance provided by the Federal Deposit Insurance Corporation or the Securities Investor Protection Corporation. Griffin is not an investment advisor. We cannot act as an advisor or provide any legal, financial, legal and/or tax advice. All information provided by Griffin is for general purposes only.
                </div>
            </section>

            <section className="pt-12 space-y-4">
                <div className="text-xl font-semibold">Griffin Services</div>
                <div>
                    Griffin provides computational power to one or multiple blockchain protocols to perform blockchain transaction verifications (“mining”) and may earn Digital Currency as mining rewards in return. These mining rewards are proportionally distributed to Griffin and all active Mining Plan holders (this process of distribution is called “Payout”).
                </div>
                <div>
                    A Mining Plan stays active for (i) at least a calendar year after activation or (ii) until all of its Payouts’ fiat value meets or exceeds 110% its amount, whichever happens later. Payouts happen every other Friday by midnight and its fiat value is calculated based on the current market rate at that time.
                </div>
                <div>
                    You can purchase Mining Plans through the Griffin Platform at your preferred amount, with a minimum of $100 and a maximum of $2,000 per Mining Plan. You may purchase multiple Mining Plans but their total amount cannot exceed $5,000 per week. We also offer promotional Mining Plans at amounts set by our sole discretion. The promotional Mining Plans’ amount is excluded from the $5,000 limit.
                </div>
                <div>
                    Griffin reserves the right to select and deploy any mining algorithm on any blockchain protocol deemed most profitable at a given time. Mining rewards will get exchanged to your Digital Currency of choice at the market rate before paid out to your Wallet. You currently have the options between Ethereum (“ETH”) and USD Coin (“USDC”). Griffin does not earn any commission from such exchanges. Griffin may amend the list of Digital Currency you can choose to be paid in from time to time by revising this Agreement.
                </div>
            </section>

            <section className="pt-12 space-y-4">
                <div className="text-xl font-semibold">Custodial Services</div>
                <div>
                    Griffin offers custodial services of your Digital Currency through our partner Wyre. We do not have or retain direct custody or control over your Digital Currency, nor we directly transmit your Digital Currency to or from your Griffin account.
                </div>
                <div>
                    Your Digital Currency is held in a custodial wallet provided by Wyre (“Wyre Wallet”). You can access your Wyre Wallet through the Griffin Platform. Wyre Wallet allows you: (i) store Digital Currency; (ii) transmit Digital Currency to other Digital Currency wallets; and (iii) exchange Digital Currency into fiat currency then immediately transmit that fiat currency to one of your linked U.S. bank accounts (“cash out”).
                </div>
                <div>
                    In order to use the custodial services, you must agree to follow the identity verification process set forth in <a className="font-semibold underline" href="https://www.sendwyre.com/legal/user-agreement" title="Wyre's user agreement" target="_blank" rel="noreferrer">Wyre’s User Agreement, Section 2 of Part A</a>. You can complete the identity verification process through the Griffin Platform.
                </div>
            </section>

            <section className="pt-12 space-y-4">
                <div className="text-xl font-semibold">Access to financial information</div>
                <div>
                    Griffin uses Plaid Inc. (“Plaid”) to link and unlink your U.S. bank accounts to your Griffin account for payment and fund transfer.
                </div>
                <div>
                    By using our Services, you authorize us and Plaid to act on your behalf to (i) access and transmit your personal and financial information from the relevant financial institution; (ii) initiate and settle financial transactions with your linked bank account.
                </div>
                <div>
                    You agree to your personal and financial information being transferred, stored, and processed by Plaid in accordance with the <a className="font-semibold underline" href="https://plaid.com/legal/" title="Plaid privacy policy" target="_blank" rel="noreferrer">Plaid Privacy Policy</a>.
                </div>
            </section>

            <section className="pt-12 space-y-4">
                <div className="text-xl font-semibold">Fees</div>
                <div>
                    <span className="font-semibold">Plan Fees.</span> You pay a one-time plan fee to activate a Mining Plan with an equivalent amount. This fee is non-refundable.
                </div>
                <div>
                    <span className="font-semibold">Operational Fees.</span> Griffin does not charge any additional operational fees.
                </div>
                <div>
                    <span className="font-semibold">Blockchain Transaction Fees.</span> The delivery and receipt of any Digital Currency is subject to network or transaction fees charged by the blockchain protocols and/or mining pools and not retained by Griffin.
                </div>
                <div>
                    <span className="font-semibold">Third-Party Fees.</span> Custodial services provided through Wyre come with no additional costs to you. Certain transactions, such as cash out, are subjected to Wyre’s fees, which are described in <a className="font-semibold underline" href="https://www.sendwyre.com/legal/user-agreement" target="_blank" title="User agreement" rel="noreferrer">Wyre’s User Agreement</a>. Any fees imposed will be displayed to you as part of the transaction process.
                </div>
            </section>

            <section className="pt-12 space-y-4">
                <div className="text-xl font-semibold">Customer Obligations</div>
                <div>
                    <span className="font-semibold">Account Creation.</span> You must register an account to access and use many features of the Griffin Platform. Registration is only permitted for authorized representatives of legal entities and individuals who are 18 years or older. You represent and warrant that you are not a person or representative of an entity barred from using the Griffin Platform under the laws of the United States, your place of residence, or any other applicable jurisdiction. You must provide accurate, current, and complete information during registration and keep your account information up-to-date. You may not register more than one account or transfer your account to someone else.
                </div>
                <div>
                    <span className="font-semibold">Account Security.</span> You are responsible for maintaining the confidentiality and security of your account credentials and liable for activities conducted through your account. You agree to notify Griffin if you suspect that your credentials have been lost, stolen, or your account is otherwise compromised. If and as permitted by applicable law, we may, but have no obligation to (i) ask you to provide identification or other information, (ii) undertake checks designed to help verify your identity or background, (iii) screen you against third-party databases or other sources and request reports from service providers, and (iv) obtain reports from public records of criminal convictions or sex offender registrations or their local equivalents.
                </div>
                <div>
                    <span className="font-semibold">Taxes.</span> You are responsible for (i) determining whether, and to what extent, any taxes apply to the Payouts you receive and the capital gains and/or losses when you conduct any transactions through the Griffin Platform; and (ii) withholding, collecting, reporting and remitting the correct amounts of taxes to the appropriate tax authorities. Your Payout and transaction history are available on the Griffin Platform.
                </div>
            </section>

            <section className="pt-12 space-y-4">
                <div className="text-xl font-semibold">Termination</div>
                <div>
                    Griffin, in its sole discretion, may suspend or terminate your access to or use of the Griffin Platform, for any reason, including, without limitation, if we receive a facially valid subpoena, court order or other binding order from a government authority requiring us to do so or if we believe you have violated this Agreement, in letter or in spirit. Griffin may also, in its sole discretion, discontinue providing the Griffin Platform, or any part thereof, with or without notice. All provisions of this Agreement which, according to their terms or their nature, should survive termination, including, without limitation, provisions with respect to Limitations of Liability and Disclaimer of Warranties, shall survive.
                </div>
            </section>

            <section className="pt-12 space-y-4">
                <div className="text-xl font-semibold">Disclaimers of Warranties</div>
                <div>
                    We provide the Griffin Platform “as is” and “as available” without warranty of any kind. To the maximum extent permitted by applicable law, Griffin disclaims all warranties, either express, implied, statutory or otherwise.
                </div>
                <div>
                    We do not warrant that the Griffin Platform will meet your requirements, or that the Platform will be uninterrupted, timely, secure, or error free. No information, whether oral or written, obtained by you from Griffin shall create any warranty not expressly made herein.
                </div>
            </section>

            <section className="pt-12 space-y-4">
                <div className="text-xl font-semibold">Limitations of Liability</div>
                <div>
                    Griffin shall not be liable for any indirect, incidental, special, exemplary, or consequential damages, including but not limited to lost profits, lost data, service interruption, computer damage or system failure arising out of or in connection with (i) the use of or inability to use the Griffin Platform, (ii) the cost of procurement of substitute services, or (iii) unauthorized access or alteration to your account or data even if Griffin has been advised of the possibility of such damages.
                </div>
                <div>
                    Griffin is not liable for any changes in value in digital currency or fiat currency. In no event will Griffin’s aggregate liability for any claim or dispute arising out of or in connection with this agreement exceed the total amount of all payments made by you to Griffin hereunder.
                </div>
                <div>
                    These limitations of liability and damages are fundamental elements of the agreement between you and Griffin. If applicable law does not allow the limitations of liability set out in these Terms, the above limitations may not apply to you.
                </div>
            </section>

            <section className="pt-12 space-y-4">
                <div className="text-xl font-semibold">Indemnification</div>
                <div>
                    To the maximum extent permitted by applicable law, you agree to indemnify and hold Griffin, its affiliates, and their personal harmless from and against any and all actions, claims, damages, losses, liabilities, and expenses, including reasonable attorneys’ fees, arising out of or in connection with: (i) your breach or violation of any of these Terms, (ii) your improper use of the Griffin Platform, or (iii) your breach of any laws, regulations or rights of any person or entity, such as intellectual property or privacy rights.
                </div>
            </section>

            <section className="pt-12 space-y-4">
                <div className="text-xl font-semibold">US Dispute Resolution and Arbitration Agreement</div>
                <div>
                    For any claim covered by this Arbitration Agreement, you agree to notify Griffin of the dispute in writing and attempt in good faith to negotiate an informal resolution. In the unlikely event that the dispute has not been resolved after 60 days, you and Griffin agree to resolve by binding arbitration by Judicial Arbitration and Mediation Services (“JAMS”).
                </div>
                <div>
                    JAMS may require you to pay a fee, unless a fee waiver was obtained from JAMS. The arbitrator may award an amount that includes your costs of arbitration, your reasonable fees for attorney, expert and other witnesses.
                </div>
                <div>
                    The arbitration will be conducted in San Bernardino County, California, unless agreed otherwise.
                </div>
                <div>
                    You agree to resolve any claim that you may have against Griffin on an individual basis in arbitration. This Arbitration Agreement will preclude you from bringing any class, collective, coordinated, consolidated, mass and/or representative action against Griffin, and from participating in or recovering relief in any action brought against Griffin by someone else.
                </div>
                <div>
                    The following disputes (and only these disputes) are excluded from this Arbitration Agreement and may be brought in any court having jurisdiction over the parties and subject matter: (i) any claims that qualify for disposition by a small claims court; (ii) any suit to compel arbitration, stay proceeding pending arbitration, or to confirm, modify, vacate or enter judgment on the award entered by the arbitrator; and (iii) any suit to seek temporary injunctive relief that will remain in place only until an arbitrator can determine whether the relief should be continued, modified or removed.
                </div>
                <div>
                    If any portion of this Arbitration Agreement is found illegal or unenforceable, that portion shall be severed and the remainder of this agreement shall be given full force and effect.
                </div>
                <div>
                    You have the right to opt out of the Arbitration Agreement by sending a timely written notice of your decision to opt out to the following address: Griffin Arbitration Opt-out, 10557 Juniper Ave Suite #E1, Fontana, CA 92337, within 30 days after entering into this agreement. You must include your name, address, and a clear statement that you want to opt out of the Arbitration Agreement. Opting out will not affect other arbitration agreements that you may currently have with Griffin, or may enter into in the future with Griffin.
                </div>
            </section>
        </div>
    )
}
