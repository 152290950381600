import React from 'react'
import { Link } from 'react-router-dom'

export function PreSignInFooter() {
  return (
    <div className="mt-10 space-y-4">
      <p className="text-content-secondary text-sm">© 2022 Griffin Labs, Inc.</p>
      <div className="flex flex-row flex-wrap space-x-4 md:space-x-8">
        <Link to="/about"><p className="text-content-primary">About us</p></Link>
        <Link to="/terms"><p className="text-content-primary">Legal</p></Link>
        <Link to="/faq"><p className="text-content-primary">FAQs</p></Link>
        <Link to="/login"><p className="text-content-primary">Login (legacy)</p></Link>
      </div>
    </div>
  )
}