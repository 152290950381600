import React, { useState } from 'react';
import InputField from './common/inputfield';
import Button, { button_type, button_width } from "./common/button";
import ExceptionText from './common/exceptiontext';
import { ValidateEmail } from '../utils/validation';
import API from '../api-service';
import { Link } from 'react-router-dom';


export default function AuthForgotPasswordScreen(props) {
  const [email, setEmail] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [exception, setException] = useState('');

  function handleEmailChange(event) {
    setEmail(event.target.value);
  }

  function handleSendForgotPasswordLink() {
    if (ValidateEmail(email)) {
      API.sendForgotPasswordEmail(email);
      setFormSubmitted(true);
    } else {
      setException("Please enter a valid email address.");
    }
  }

  return (
    <div className="main_griffin_container">
      <Link to="/"><img alt="Griffin logo" className="w-8 lg:w-10 pb-10" src="https://res.cloudinary.com/dqafyoayf/image/upload/v1629697839/Griffin_Logomark_-_Red_kuseg9.svg"/></Link>
      <div className="text-2xl font-semibold py-4">Recover password</div>

      {!formSubmitted 
        ? <div>
            <p className="pb-4">
              Don't worry, happens to the best of us.
            </p>
            {exception && <ExceptionText>{exception}</ExceptionText>}
            <InputField
              type="email"
              name="email"
              label="Email address"
              onChange={handleEmailChange}
            />
            <div className="mt-10">
              <Button button_type={button_type.dark} button_width={button_width.full_screen} onClick={handleSendForgotPasswordLink}>
                Email me a recovery link
              </Button>
            </div>
          </div>
        : <p>
            We've sent a password reset link to your email address. Please open
            your email and click on the link to continue.
          </p>
      }
    </div>
  );
}