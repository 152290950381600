import React from 'react';
import './button.scss';

export const button_type = {
  primary: 'button_primary',
  default: 'button_default',
  hairline: 'button_hairline',
  underline: 'button_underline',
  text: 'button_text',
  textOnDark: 'button_text_onDark',
  underlineOnDark: 'button_underline_onDark',
  hairlineOnDark: 'button_hairline_onDark',
  dark: "button_dark",
}

export const button_size = {
  default: 'button_size_default',
  small: 'button_size_small'
}

export const button_width = {
  fit: 'button_width_fit',
  full_screen: 'button_width_full_screen'
}

export default class Button extends React.Component {

  render() {
    const b_type = this.props.button_type || button_type.primary;
    const b_size = this.props.button_size || button_size.default;
    const b_width = this.props.button_width || button_width.fit;
    const class_name = `${b_type} ${b_size} ${b_width}`;
    const disabled = this.props.disabled ? true : false;
    const type = this.props.type ? this.props.type : "button";

    return(
      <button className={class_name} disabled={disabled} type={type} onClick={this.props.onClick}>
        {this.props.children || 'Label'}
      </button>
    )
  }
}