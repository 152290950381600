import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import InputField from './common/inputfield';
import Button, {button_type, button_size, button_width} from './common/button';
import ExceptionText from './common/exceptiontext';
import { ValidateEmail } from '../utils/validation';
import { GetResponseErrorMessage } from '../utils/request';
import API from '../api-service';
import { Link } from 'react-router-dom';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import WarningIcon from '@material-ui/icons/Warning'

export default function AuthLoginScreen() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [disabledCta, setDisabledCta] = useState(false);
  const [v2UserException, setV2UserException] = useState(false);
  const [exception, setException] = useState('');
  const [cookies, setCookie] = useCookies(['griffin-token']); // eslint-disable-line no-unused-vars
  
  const history = useHistory();

  function handleEmailChange(event) {
    setEmail(event.target.value);
  }

  function handlePasswordChange(event) {
    setPassword(event.target.value);
  }


  function handleForgotPassword(event) {
    history.push("/forgot-password");
  }

  function handleLogin() {
    if (!ValidateEmail(email)) {
      setException("Please enter a valid email address.");
    } else {
      setException('');
      setDisabledCta(true);
      API.login(email, password)
      .then(response => {
        if (!response.ok) {
          throw response;
        } else {
          setDisabledCta(false);
          response.json().then(user_info => {
            if (user_info.data['version'] !== 'v1') {
              setV2UserException(true);
            } else {
              setCookie('griffin-token', user_info.data['access_token'], {path: '/', maxAge: 2592000});
            }
          });
        }
      })
      .catch(error => {
        setDisabledCta(false);
        error.json().then(err => setException(GetResponseErrorMessage(err)));
      });
    }
  }

  return (
    <div className="main_griffin_container">
      <Link to="/"><img alt="Griffin logo" className="w-8 lg:w-10 pb-10" src="https://res.cloudinary.com/dqafyoayf/image/upload/v1629697839/Griffin_Logomark_-_Red_kuseg9.svg"/></Link>
      
      <V1InfoBox />
      <div className="text-2xl font-semibold py-4">Welcome back!</div>
      <p className="pb-4">Log in to access your account</p>
      {v2UserException && <V2UserInfoBox />}
      {exception && <ExceptionText>{exception}</ExceptionText>}
      <InputField type="email" name="email" label="Email address" onChange={handleEmailChange}/>
      <InputField type="password" name="password" label="Enter your password" onChange={handlePasswordChange}/>
      <div className="-mt-2 space-y-10">
        <Button button_type={button_type.underline} button_size={button_size.small} disabled={disabledCta} onClick={handleForgotPassword}>
          Forgot your password?
        </Button>
        <Button onClick={handleLogin} button_type={button_type.dark} button_width={button_width.full_screen} disabled={disabledCta}>Log in</Button>
      </div>
    </div>
  )
}

function V1InfoBox(props) {
  return (
    <div className="block rounded-xl bg-red-lighter transition-all">
      <div className="p-4 text-sm flex space-x-4">
        <div className="text-base flex pt-0.5">
          <InfoOutlinedIcon fontSize='inherit'/>
        </div>
        <div className='space-y-4'>
          <div>This is the login page for the legacy accounts who have purchased a mining plan before July 1, 2022.</div>
          <div>To access the new version, <Link to="/" className="underline font-semibold">use Griffin app</Link></div>
        </div>
      </div>
    </div>
  )
}

function V2UserInfoBox(props) {
  return (
    <div className="block rounded-xl bg-red-lighter transition-all">
      <div className="p-4 text-sm flex space-x-4">
        <div className="text-base flex pt-0.5">
          <WarningIcon fontSize='inherit'/>
        </div>
        <div>It seems you’re trying to log in to the new Griffin version. <Link to="/" className="underline font-semibold">Use the app</Link></div>
      </div>
    </div>
  )
}