import React from 'react';
import './exceptiontext.scss';


export default function ExceptionText(props) {
  return (
    <p className="exception_text">{props.children}</p>
  )
}

export function ErrorFetchingData() {
  return (
    <div>
      <h1>Oops...</h1>
      <p className='py-3'>Look like something went wrong, please try again in a few minutes.</p>
    </div>
  )
}