import React, { useEffect, useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import './wallet.scss'
import { useCookies } from 'react-cookie'
import API from '../../api-service'
import Tag from '../common/tag'
import HairlineBreak from '../common/hairlinebreak'
import Button, { button_type, button_size } from '../common/button'
import InputField from '../common/inputfield'
import ExceptionText from '../common/exceptiontext'
import ModalView from '../common/modal';
// import FAQBlockItem from '../common/faqitem'
import { UserContext } from '../../context'


export default function AccountMiningWalletsPage(props) {

  const [wallets, setWallets] = useState([]);
  const [cookies, removeCookie] = useCookies(['griffin-token']);
  const history = useHistory(UserContext);

  function getUserWallets() {
    if (cookies['griffin-token'] === undefined || cookies['griffin-token'] === 'undefined') return;

    API.getUserWallets(cookies['griffin-token'])
      .then(response => {
        if (response.status === 401) {
          removeCookie('griffin-token');
        } else if (response.ok) {
          response.json().then(wallets_data => {
            setWallets(wallets_data.data);
          });
        }
      });
  }

  useEffect(() => {
    getUserWallets();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function handleTriggerWalletCreationFlow() {
    history.push('/account/wallet/create');
  }

  return(
    <div>
      <div className="main_griffin_container">
        <h1>Mining wallets</h1>
        {wallets.map((wallet) => <WalletRow key={wallet.id} wallet={wallet} handleDataChange={getUserWallets}/>)}
      </div>
      <div className="fixed w-screen bottom-0 bg-white">
          <HairlineBreak />
          <div id="wallet_create_cta">
            <div id="wallet_create_button">
              <Button onClick={handleTriggerWalletCreationFlow}>Add new wallet address</Button>
            </div>
          </div>
      </div>
    </div>
  )
}

function WalletRow(props) {
  const [showCta, setShowCta] = useState(false);
  const [showMakePrimaryModal, setShowMakePrimaryModal] = useState(false);
  const [showDeactivationModal, setShowDeactivationModal] = useState(false);
  const [disabledCta, setDisabledCta] = useState(false);
  const [cookies, removeCookie] = useCookies(['griffin-token']);
  const [user, setUser] = useContext(UserContext)

  function handleRowClick() {
    if (!props.wallet.is_primary) {
      setShowCta(!showCta);
    }
  }

  function handleMakePrimaryClick(event) {
    event.stopPropagation();
    setShowMakePrimaryModal(true);
  }

  function handleMakePrimaryModalDismissal() {
    setShowMakePrimaryModal(false);
  }

  function handleMakePrimaryConfimationClick() {
    if (cookies['griffin-token'] === undefined || cookies['griffin-token'] === 'undefined') return;

    API.makeWalletPrimary(cookies['griffin-token'], props.wallet.id)
    .then(response => {
      if (response.status === 401) {
        removeCookie('griffin-token');
      } else if (response.ok) {
        setShowMakePrimaryModal(false);
        props.handleDataChange();
        user['eth_wallet_address'] = props.wallet.coin_address;
        setUser(user);
      } else {
        // Should not arrive here. In which case, just let user click on button again to retrigger the event
        setDisabledCta(false);
      }
    });
  }

  function handleDeactivationClick(event) {
    event.stopPropagation();
    setShowDeactivationModal(true);
  }

  function handleDeactiviationModalDismissal() {
    setShowDeactivationModal(false);
  }

  function handleDeactivationConfimationClick() {
    if (cookies['griffin-token'] === undefined || cookies['griffin-token'] === 'undefined') return;

    setDisabledCta(true);
    API.deleteWallet(cookies['griffin-token'], props.wallet.id)
    .then(response => {
      if (response.status === 401) {
        removeCookie('griffin-token');
      } else if (response.ok) {
        props.handleDataChange();
      } else {
        // Should not arrive here. In which case, just let user click on button again to retrigger the event
        setDisabledCta(false);
      }
    });
  }

  return(
    <div className={`WalletRow ${props.wallet.is_primary ? '' : 'Clickable'}`} onClick={handleRowClick}>
      <div className="WalletRowIdInfoContainer">
        <p className="WalletRowWalletName">{props.wallet.name}</p>
        {props.wallet.is_primary && <div className="WalletRowIsPrimaryTag"><Tag>Primary</Tag></div>}
      </div>
      <p className="WalletRowCoinAddress">{props.wallet.coin_address}</p>
      {showCta &&
      <div className="WalletRowCtaContainer">
        <Button button_type={button_type.underline} button_size={button_size.small}  onClick={handleMakePrimaryClick}>Make this your primary wallet</Button>
        <div className="WalletRowDeactivateCta">
          <Button button_type={button_type.primary} button_size={button_size.small} onClick={handleDeactivationClick}>Delete</Button>
        </div>
      </div>
      }
      <ModalView
        open={showDeactivationModal}
        title="Delete your wallet"
        text="Are you sure you want to delete this wallet?"
        disabled={disabledCta}
        primaryAction="Delete"
        onPrimaryActionClick={handleDeactivationConfimationClick}
        secondaryAction="Dismiss"
        onSecondaryActionClick={handleDeactiviationModalDismissal}
        onDismissClick={handleDeactiviationModalDismissal}/>
      <ModalView
        open={showMakePrimaryModal}
        title="Make wallet primary"
        text="By making this wallet primary, your subsequent payouts will be made to this address instead. Would you like to proceed?"
        disabled={disabledCta}
        primaryAction="Continue"
        onPrimaryActionClick={handleMakePrimaryConfimationClick}
        secondaryAction="Dismiss"
        onSecondaryActionClick={handleMakePrimaryModalDismissal}
        onDismissClick={handleMakePrimaryModalDismissal}/>
    </div>
  )
}

export function CreateNewWalletPage(props) {
  const [exception, setException] = useState(null);
  const [disabledCta, setDisabledCta] = useState(false);
  const [walletName, setWalletName] = useState('');
  const [walletAddress, setWalletAddress] = useState('');
  const [showAccountCreatedAndRedirectPage, setShowAccountCreatedAndRedirectPage] = useState(false);
  const [cookies, removeCookie] = useCookies(['griffin-token']);
  const [user, setUser] = useContext(UserContext);
  const history = useHistory();


  function handleWalletNameChange(event) {
    setWalletName(event.target.value);
  }

  function handleWalletAddressChange(event) {
    setWalletAddress(event.target.value);
  }

  function handleCreateNewWallet(event) {
    if (walletName.length === 0) {
      setException("Enter a memorial name for this wallet.");
    } else if (!walletAddress.startsWith("0x") || walletAddress.length < 3) {
      setException("Please enter a valid wallet address.");
    } else {
      if (cookies['griffin-token'] === undefined || cookies['griffin-token'] === 'undefined') return;
      
      setDisabledCta(true);
      API.createEthereumWallet(cookies['griffin-token'], walletName, walletAddress)
      .then(response => {
        setDisabledCta(false);
        if (response.status === 401) {
          removeCookie('griffin-token');
        } else if (response.ok) {
          if (!props.is_new_wallet_primary) {
            setShowAccountCreatedAndRedirectPage(true);
          } else {
            let userCopy = JSON.parse(JSON.stringify(user));
            userCopy['eth_wallet_address'] = walletAddress;
            setUser(userCopy);
          }
        } else {
          setException("Internal server error, please try again in a few minutes");
        }
      });
    }
  }

  function handleNavigateToAccountWalletsPage() {
    history.push('/account/wallet');
  }


  return(
    <div>
      {!showAccountCreatedAndRedirectPage &&
      <div>
        <div className="main_griffin_container">
          <div className="text-2xl font-semibold pb-4">Add your wallet</div>
          <p className="py-3">To continue, you’ll need to provide an ETH wallet address to receive and safely store your mining rewards.</p>
          <div className="py-4">
            {exception && <ExceptionText>{exception}</ExceptionText>}
            <InputField type="text" name="name" label="Wallet name" onChange={handleWalletNameChange}/>
            <InputField type="text" name="address" label="ETH wallet address" onChange={handleWalletAddressChange}/>
          </div>
        </div>
        {/* <AddWalletFaq /> */}
        <div className="fixed w-screen bottom-0 bg-white">
          <HairlineBreak />
          <div id="wallet_create_cta">
            <div id="wallet_create_button">
              <Button disabled={disabledCta} onClick={handleCreateNewWallet}>Add wallet</Button>
            </div>
          </div>
        </div>
      </div>
      }

      {showAccountCreatedAndRedirectPage &&
      <div className="main_griffin_container">
        <div className="text-2xl font-semibold pb-4">ETH wallet created</div>
        <p className="py-3">You have successfully created a new wallet. If you want to receive payout on this wallet in the future, please enable it on your account dashboard. </p>
        <Button button_type={button_type.underline} disabled={disabledCta} onClick={handleNavigateToAccountWalletsPage}>
          Take me to my wallets dashboard →
        </Button>
      </div>
      }
    </div>
  )
}
