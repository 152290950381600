import React from 'react'
import { Tabs, Tab } from '@material-ui/core'
import PaymentIcon from '@material-ui/icons/Payment'
import SubscriptionsIcon from '@material-ui/icons/Subscriptions'
import PersonIcon from '@material-ui/icons/Person'
import { useHistory } from 'react-router-dom'
import HairlineBreak from './hairlinebreak'

export const tab_bar_value = {
  'EARNING': 'earning-tab-bar',
  'PLAN': 'plan-tab-bar',
  'PROFILE': 'profile-tab-bar'
}

export default function TabBar(props) {
  const history = useHistory();

  function handleTabBarChange(event, newValue) {
    if (newValue === tab_bar_value.EARNING) {
      history.push('/dashboard');
    } else if (newValue === tab_bar_value.PLAN) {
      history.push('/plan');
    } else if (newValue === tab_bar_value.PROFILE) {
      history.push('/account');
    }
  }

  return (
    <div>
      <div className="fixed bottom-0 w-screen visible md:invisible bg-white z-20">
        {/* <TaxBanner /> */}
        <HairlineBreak />
        <div className="pb-2">
          <Tabs
            value={props.tab_bar_value}
            onChange={handleTabBarChange}
            variant="fullWidth"
            TabIndicatorProps={{
              style: {
                display: "none",
              },
            }}
            textColor="primary"
            aria-label="griffin bottom navigation tab bar"
          >
            <Tab icon={<PaymentIcon />} label="Earning" value={tab_bar_value.EARNING} style={{textTransform: 'none'}}/>
            <Tab icon={<SubscriptionsIcon />} label="Plan" value={tab_bar_value.PLAN} style={{textTransform: 'none'}} />
            <Tab icon={<PersonIcon />} label="Account" value={tab_bar_value.PROFILE} style={{textTransform: 'none'}}/>
          </Tabs>
        </div>
      </div>
      {/* <div className="fixed bottom-0 w-screen invisible md:visible bg-white z-20">
        <TaxBanner />
      </div> */}
    </div>
  )
}