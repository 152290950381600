import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import currencyFormatter from 'currency-formatter'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEthereum } from '@fortawesome/free-brands-svg-icons'
import API from '../api-service'
import Toggle from './common/toggle'
import { Topbar } from './common/topbar'
import TabBar, { tab_bar_value } from './common/tabbar'
import { ErrorFetchingData } from './common/exceptiontext'
import moment from 'moment';

export default function EarningScreen(props) {
  const [earning, setEarning] = useState(null);
  const [showFetchingError, setShowFetchingError] = useState(false);
  const [showFiatAmount, setShowFiatAmount] = useState(false);
  const [payouts, setPayouts] = useState([]);
  const [cookies, removeCookie] = useCookies(['griffin-token']);

  useEffect(() => {
    function retrieveCurrentEarning() {
      if (cookies['griffin-token'] === undefined || cookies['griffin-token'] === 'undefined') return;

      API.getEarningSummary(cookies['griffin-token'])
        .then(response => {
          if (response.status === 401) {
            removeCookie('griffin-token');
          } else if (response.ok) {
            response.json().then(earning_info => {
              setEarning(earning_info.data);
            });
          } else {
            setShowFetchingError(true);
          }
        })
    }

    function retrievePayoutHistory() {
      if (cookies['griffin-token'] === undefined || cookies['griffin-token'] === 'undefined') return;
      
      API.getPastPayout(cookies['griffin-token'])
        .then(response => {
          if (response.status === 401) {
            removeCookie('griffin-token');
          } else if (response.ok) {
            response.json().then(payout_history => {
              setPayouts(payout_history.data);
            });
          }
        })
    }
    retrieveCurrentEarning();
    retrievePayoutHistory();
    const current_earning_interval_id = setInterval(() => retrieveCurrentEarning(), 900000);
    const payout_history_internal_id = setInterval(() => retrievePayoutHistory(), 3600000);
    return () => {
      clearInterval(current_earning_interval_id);
      clearInterval(payout_history_internal_id);
    }
  }, [cookies, removeCookie]);  // eslint-disable-line react-hooks/exhaustive-deps

  function switchCoinAndFiatAmount(isFiat) {
    setShowFiatAmount(isFiat);
  }
  
  return (
    <div>
      <Topbar/>
      <div className="main_griffin_container main_griffin_container_extra_bottom_padding">
        {showFetchingError && <ErrorFetchingData />}
        {earning !== null &&  
          <div>
            <p className="text-content-secondary">Total paid to date</p>
            <div className="flex">
              <p className="flex-grow text-4xl py-2 font-semibold">
                {showFiatAmount 
                  ? <span>${currencyFormatter.format(earning.total_paid_fiat, 'USD')}</span>
                  : <span>{earning.total_paid_eth.toFixed(4)} <span className="text-xl font-medium">{earning.earning_currency}</span></span>
                }
              </p>
              <div className="my-auto">
                <Toggle unchecked_label={<FontAwesomeIcon icon={faEthereum} />} checked_label="$" onToggleStateChange={switchCoinAndFiatAmount}/>
              </div>
            </div>
            {!showFiatAmount && 
                <p className="font-semibold pb-4 -pt-2">${currencyFormatter.format(earning.total_paid_fiat_with_current_coin_value, 'USD')} <span className="font-normal text-content-secondary pl-1">estimated value</span></p>
            }
            <Earning earning={earning} show_fiat_amount={showFiatAmount} />
          </div>
        }
        <Payouts payouts={payouts} show_fiat_amount={showFiatAmount} />
      </div>
      <TabBar tab_bar_value={tab_bar_value.EARNING} />
    </div>
  )
}

function Payouts(props) {
  return(
    <div>
      {(props.payouts.length > 0) &&
        <div>
          <p className="mt-10 pb-2 text-lg font-medium">Payouts</p>
          <div className="divide-y divide-gray-100">
            {props.payouts.map((payout) => <Payout key={payout.transaction_id} payout={payout} show_fiat_amount={props.show_fiat_amount} />)}
          </div>
        </div>
      }
    </div>
  )
}

function Payout(props) {
  var payout_date = moment.unix(props.payout.payout_timestamp).format("MMM DD, YYYY");
  if (payout_date === moment().format("MMM DD, YYYY")) {
    payout_date = 'Today';
  } else if (payout_date === moment().add(-1, 'days').format("MMM DD, YYYY")) {
    payout_date = 'Yesterday';
  }
  return(
    <div className='py-6'>
      <div className="flex">
        <p className="flex-grow">Sent to {props.payout.payout_address_name}</p>
        {props.show_fiat_amount 
          ? <p>${currencyFormatter.format(props.payout.fiat_amount, 'USD')}</p>
          : <p>{parseFloat(props.payout.amount).toFixed(5)} {props.payout.payout_currency}</p>
        }
      </div>
      <p className="text-sm text-content-secondary pt-2">{payout_date}</p>
    </div>
  )
}

function Earning(props) {
  const [showPeriod, setShowPeriod] = useState(false);
  const is_period_ended = props.earning.time_unit_until_payout_value === 0;
  const is_payout_processed = props.earning.payout_amount !== 0;
  const show_minimum_payout_not_meet = is_period_ended && !is_payout_processed && (props.earning.total_unpaid < props.earning.minimum_payout_threshold);
  const earning_progress_style = {
    width: ((14 - props.earning.time_unit_until_payout_value) / 14.0 * 100).toString() + '%',
    backgroundColor: '#FF3876',
  }

  return (
    <div>
      <div className="cursor-pointer border rounded-xl border-gray-200 my-6 -mx-2 p-4" onClick={() => setShowPeriod(!showPeriod)}>
        <p className="text-content-secondary">Current mining reward: 
          <span className="pl-1 text-content-primary font-semibold">{props.show_fiat_amount ? '≈ $' + currencyFormatter.format(props.earning.total_unpaid_fiat_with_current_coin_value, 'USD') : props.earning.total_unpaid_eth.toFixed(4) + ' ETH'}</span></p>
        <div className="my-4 h-2 bg-gray-100 rounded">
          <div className="h-full rounded" style={earning_progress_style}></div>
        </div>
        {showPeriod 
          ? <div className="flex">
              <p className="flex-grow text-sm text-content-secondary">{moment(props.earning.next_payout_date).add(-2, "weeks").format("MM/DD")}</p>
              <p className="text-sm text-content-secondary">{moment(props.earning.next_payout_date).format("dddd MM/DD")}</p>
            </div>
          : show_minimum_payout_not_meet 
              ? <p className="text-sm text-content-secondary">{props.earning.minimum_payout_threshold} {props.earning.earning_currency} minimum payout required</p>
              : <p className="text-sm font-semibold">{props.earning.time_unit_until_payout_value} {props.earning.time_unit_until_payout_name}{props.earning.time_unit_until_payout_value > 1 && 's'} <span className="text-content-secondary font-normal"> until payout</span></p>
        }
      </div>
    </div>
  )
}
