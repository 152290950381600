export default class API {
  static getApiEndpoint() {
    return (process.env.NODE_ENV === 'production') ? 'https://api.griffincrypto.com' : 'http://localhost:8000';
  }

  static async login(email, password) {
    const url = `${API.getApiEndpoint()}/auth/login`;
    return fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        'email': email,
        'password': password
      })
    });
  }

  static async sendForgotPasswordEmail(email) {
    const url = `${API.getApiEndpoint()}/auth/send-password-reset-email`;
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        'email': email
      })
    });
  }

  static async resetUserPassword(token, uidb64, password) {
    const url = `${API.getApiEndpoint()}/auth/reset-password`;
    return fetch(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        'token': token,
        'uidb64': uidb64,
        'password': password
      })
    });
  }

  static async getEarningSummary(token) {
    const url = `${API.getApiEndpoint()}/payout/earning-summary`
    return fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });
  }

  static async getPastPayout(token) {
    const url = `${API.getApiEndpoint()}/payout/list-payouts`
    return fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });
  }

  static async getUserWallets(token) {
    const url = `${API.getApiEndpoint()}/wallet/list-active-wallets`
    return fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });
  }

  static async createEthereumWallet(token, wallet_name, wallet_address) {
    const url = `${API.getApiEndpoint()}/wallet/create`;
    return fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({
        'coin_type': 'ETH',
        'coin_address': wallet_address,
        'name': wallet_name
      })
    });
  }

  static async deleteWallet(token, wallet_id) {
    const url = `${API.getApiEndpoint()}/wallet/delete`;
    return fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({
        'id': wallet_id
      })
    });
  }

  static async makeWalletPrimary(token, wallet_id) {
    const url = `${API.getApiEndpoint()}/wallet/change-primary`;
    return fetch(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({
        'id': wallet_id
      })
    });
  }

  static async getUserInfo(token) {
    const url = `${API.getApiEndpoint()}/auth/user-info`;
    return fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });
  }

  static async getClientPaymentToken(token) {
    const url = `${API.getApiEndpoint()}/subscription/get-payment-client-token`;
    return fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });
  }

  static async getSubscriptionDetail(token, subscription_id) {
    const url = `${API.getApiEndpoint()}/subscription/detail?id=${subscription_id}`;
    return fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });
  }

  static async getPaymentReceiptDetail(token, receipt_id) {
    const url = `${API.getApiEndpoint()}/subscription/receipt/detail?id=${receipt_id}`;
    return fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });
  }

  static async getPaymentReceipts(token) {
    const url = `${API.getApiEndpoint()}/subscription/receipt/list`;
    return fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });
  }

  static async sendCustomerSupportMessage(message_type, name, email, message) {
    const url = `${API.getApiEndpoint()}/support/create`;
    return fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        'type': message_type,
        'name': name,
        'email': email,
        'message': message
      })
    });
  }

  static async getListTax(token){
    const url = `${API.getApiEndpoint()}/tax/`;
    return fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });
  }
}
