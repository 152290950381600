import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import InputField from './common/inputfield';
import Button, { button_width, button_type } from './common/button';
import ExceptionText from './common/exceptiontext';
import { ValidatePassword } from '../utils/validation';
import { GetRequestParam, GetResponseErrorMessage } from '../utils/request';
import API from '../api-service';
import { Link } from 'react-router-dom';


export default function AuthResetPasswordScreen(props) {
  const [password, setPassword] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [disabledCta, setDisabledCta] = useState(false);
  const [exception, setException] = useState('');

  const history = useHistory();

  function handlePasswordChange(event) {
    setPassword(event.target.value);
  }

  function handlePasswordReset() {
    if (!ValidatePassword(password)) {
      if (password.length < 8) {
        setException("Password must has a minimum of 8 characters.");
      } else {
        setException("Password must must contains at least one letter and one number.");
      }
    } else {
      setException('');
      setDisabledCta(true);
      API.resetUserPassword(GetRequestParam('token'), GetRequestParam('uidb64'), password)
      .then(response => {
        if (!response.ok) {
          throw response;
        } else {
          setDisabledCta(false);
          setFormSubmitted(true);
        }
      })
      .catch(error => {
        setDisabledCta(false);
        error.json().then(err => setException(GetResponseErrorMessage(err)));
      });
    }
  }

  function handleNavigateToLogin() {
    history.push("/login");
  }

  return (
    <div className="main_griffin_container">
      <Link to="/"><img alt="Griffin logo" className="w-8 lg:w-10 pb-10" src="https://res.cloudinary.com/dqafyoayf/image/upload/v1629697839/Griffin_Logomark_-_Red_kuseg9.svg"/></Link>

      {formSubmitted 
        ? <div>
            <div className="text-2xl font-semibold py-4">Password reset successful</div>
            <Button
              button_type={button_type.underline}
              disabled={disabledCta}
              onClick={handleNavigateToLogin}
            >
              Log in to access your account →
            </Button>
          </div>
        : <div>
            <div className="text-2xl font-semibold py-4">Reset your password</div>
            <p className="pb-4">Please enter your new password below.</p>
            {exception && <ExceptionText>{exception}</ExceptionText>}
            <InputField
              type="password"
              name="password"
              label="Enter new password"
              disable_autocomplete="true"
              onChange={handlePasswordChange}
            />
            <div className="mt-10">
              <Button onClick={handlePasswordReset} button_type={button_type.dark} button_width={button_width.full_screen} disabled={disabledCta}>Reset password</Button>
            </div>
          </div>
      }
    </div>
  );
}