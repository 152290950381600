import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import "typeface-inter"
import "@fontsource/fraunces"
import "@fontsource/montserrat"
import { CookiesProvider } from 'react-cookie'
import { ThemeProvider, createTheme, CssBaseline } from '@material-ui/core'

const theme = createTheme({
  typography: {
    fontFamily: 'Inter, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
    fontSize: 16,
  },
  palette: {
    background: {
      default: "#FFFFFF",
    },
    primary: {
      light: '#FF3876',
      main: '#FF3876',
      dark: '#FF3876',
      contrastText: '#fff',
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <CookiesProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </CookiesProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
