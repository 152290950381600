import React, { useState } from 'react';
import './toggle.scss';

export default function Toggle(props) {

  const [enable, setEnable] = useState(false);

  function onToggle(event) {
    const isEnable = !enable;
    setEnable(isEnable);
    props.onToggleStateChange(isEnable);
  }

  return (
    <div className={`toggle_container ${enable ? 'checked' : ''}`} onClick={onToggle}>
      <div className="toggle_selector"></div>
      <div className="toggle_unchecked_label">{props.unchecked_label}</div>
      <div className="toggle_checked_label">{props.checked_label}</div>
    </div>
  )
}

