import React from 'react'
import { Link } from 'react-router-dom';
import { PreSignInFooter } from './common/footer';

export default function AboutScreen() {
  return (
    <div className="bg-gradient-to-b from-aqua-lighter via-red-lighter to-purple-lighter py-10 lg:py-20">
      <div className="container md:max-w-7xl lg:max-w-screen-2xl flex flex-col lg:flex-row px-8 md:px-16 xl:px-56 lg:items-end">
        <Link to="/"><img alt="Griffin logo" className="w-8 lg:w-10 pb-10" src="https://res.cloudinary.com/dqafyoayf/image/upload/v1629697839/Griffin_Logomark_-_Red_kuseg9.svg"/></Link>
      </div>
      <div className="container md:max-w-7xl lg:max-w-screen-2xl flex flex-col lg:flex-row px-8 md:px-16 xl:px-56 lg:items-end">
        <div className="w-full md:w-2/3 space-y-4">
          <div className="text-xs uppercase tracking-wider text-red-medium">Why Griffin</div>
          <h1 className="text-5xl md:text-7xl font-light tracking-tighter leading-tight">Making Crypto Mining Easy and Profitable for Everyone.</h1>
        </div>
        <div className="w-full md:w-1/3 transform translate-y-16 lg:translate-y-24 md:scale-150 self-end">
          <img src="https://res.cloudinary.com/dqafyoayf/image/upload/v1624338088/landingPage-CTA_yx09l8.svg" alt="" />
        </div>
      </div>

      <div className="container px-8 md:px-16 xl:px-56 py-32 space-y-40">
        <div className="flex flex-col lg:flex-row space-x-0 md:space-x-56 space-y-56 lg:space-y-0 md:items-center lg:items-start">

          <div className="w-full md:w-2/5 space-y-8 md:text-center lg:text-left">
            <h3 className="font-heading text-4xl md:text-6xl font-light tracking-tighter">The Griffin Difference</h3>
            <p>Making crypto mining accessible and profitable for everyone.</p>
          </div>

          <div className="w-full md:w-3/5 flex flex-col">
            <figure className="flex flex-row justify-between">
              <img className="w-48 md:w-64 absolute right-0 md:right-auto rounded-xl transform -translate-x-20 md:-translate-x-40 -translate-y-40 md:-translate-y-16" src="https://res.cloudinary.com/dqafyoayf/image/upload/v1648331135/DSCF6945_rptbku.jpg" alt="portrait of Minh-Thao Nguyen, Griffin Co-founder & CEO" />
              <div className="bg-white rounded-xl p-10 md:pl-32 space-y-8">
                <blockquote>
                  <p className="font-heading text-8xl font-normal text-purple-dark leading-none -mb-6">“</p>
                  <p>We created Griffin because we saw a need in the market for an easier way to invest in crypto without the volatility and risk associated with buying crypto outright.</p>
                </blockquote>
                <figcaption className="text-xs uppercase tracking-wider text-red-medium space-y-1">
                  <div>Minh-Thao Nguyen,</div>
                  <div>Griffin Co-founder & CEO</div>
                </figcaption>
              </div>
            </figure>
          </div>
          
        </div>

        <div className="flex flex-col md:flex-row space-y-8 md:space-y-0 space-x-0 md:space-x-16">
          <div className="w-full md:w-1/3 border-content-tertiary border-t pt-8 space-y-6">
            <div className="font-heading text-2xl text-red-medium">01</div>
            <div className="text-2xl tracking-tight">Customer First</div>
            <p>At Griffin, we focus on customers over profit. We want to build a company that makes money with our customers, not from them.</p>
          </div>
          <div className="w-full md:w-1/3 border-content-tertiary border-t pt-8 space-y-6">
            <div className="font-heading text-2xl text-red-medium">02</div>
            <div className="text-2xl tracking-tight">Built For Human</div>
            <p>Our product is free from industry's jargons. We commit to craft user experiences that are easy to understand and delightful to use.</p>
          </div>
          <div className="w-full md:w-1/3 border-content-tertiary border-t pt-8 space-y-6">
            <div className="font-heading text-2xl text-red-medium">03</div>
            <div className="text-2xl tracking-tight">Transparency</div>
            <p>A company can only succeed if built on a foundation of trust. We’re committed to earn our customers’ trust by being as open and transparent as possible.</p>
          </div>
        </div>

        <div className="flex flex-col md:flex-row space-x-0 md:space-x-32 items-end">
          <div className="w-full md:w-1/2 space-y-4">
            <div className="text-xs uppercase tracking-wider text-red-medium">Contact us</div>
            <div className="font-heading font-light text-4xl md:text-6xl tracking-tighter">We’d Love to Hear from You</div>
          </div>
          <div className="w-full md:w-1/2 space-y-2 transform translate-y-4">
            <p>Have a general question? Interested in a career at Griffin? Inquiring about press or media resources?</p>
            <a className="inline-block font-semibold underline hover:text-red-medium py-4 cursor-pointer" href="mailto:hello@griffincrypto.com">Send us an email</a>
          </div>
        </div>
      </div>
      <div className="container px-8 md:px-16 xl:px-56 -mt-16"><PreSignInFooter /></div>
    </div>
  )
}