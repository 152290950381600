import React from 'react'
import { useHistory } from 'react-router-dom'
import Heading from '../common/heading'
import TaxDisclosure from './tax-disclosure'
import InfoBox from '../common/infobox'
import Button, {button_type, button_size} from '../common/button';

export default function TaxGainsLosses(props) {

  const history = useHistory()

  function handleNavigateToTaxes(event) {
    history.push("/tax");
  }

  return (
    <div>
      <div className="main_griffin_container space-y-10">
        <div className='space-y-6'>
          <Heading text="Report crypto gains/losses" />
          <div className='space-y-4'>
            <p>Crypto from mining is treated as ordinary income only at receiving time. After that, the IRS considers crypto as property for tax purposes.</p>
            <p>You are required to report their capital gains and losses on the same Form 8949 as stocks and equities.</p>
            <p>As most exchanges don’t often provide a condensed form for gains/losses as traditional brokers do with stock trading, you’ll likely need to use a tool like CoinTracker.</p>
          </div>
          <InfoBox url='../cointracker' content='If you trade with Coinbase, you can get Form 8949 from CoinTracker for free.'/>
        </div>
        
        <div className='space-y-6'>
          <Button button_type={button_type.underline} button_size={button_size.small} onClick={handleNavigateToTaxes}>Back to Taxes</Button>
          <TaxDisclosure />
        </div>
        
      </div>
    </div>
  )
}