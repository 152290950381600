import React from 'react'
import { useHistory } from 'react-router-dom'
import SectionBreak from '../common/sectionbreak'
import Heading from '../common/heading'
import GetAppIcon from '@material-ui/icons/GetApp'
import TaxDisclosure from './tax-disclosure'
import InfoBox from '../common/infobox'
import Button, {button_type, button_size} from '../common/button';

export default function TaxAsBusiness(props) {

  const history = useHistory()

  function handleNavigateToTaxes(event) {
    history.push("/tax");
  }

  return (
    <div>
      <div className="main_griffin_container space-y-10">
        <div className='space-y-6'>
          <Heading text="Mining crypto as a business" />
          <div className='space-y-4'>
            <p>You can mine crypto as a hobby or a business.</p>
            <p>Mining as a business allows you to deduct some of your expenses associated with the business. These deductions are not available for hobby miners.</p>
            <p>Even though you don’t dedicate time to being hands-on with your Griffin subscription on a regular basis, your primary purpose for using Griffin is for income or profit, which should qualify it as a business.</p>
          </div>
        </div>

        <SectionBreak />

        <div className='space-y-6'>
          <Heading text="How do I report mining as a business?" />
          <div className='space-y-4'>
            <p>You need to file a Schedule C (Form 1040).</p>
            <p>Schedule C is a tax form for reporting profit or loss from a business. It is typically for people who operate sole proprietorships or single-member LLCs.</p>
            <p>You fill out and attach Schedule C when filing with Form 1040.</p>
            <p>Based on your mining activity, we filled out a sample Schedule C for your reference.</p>
          </div>
          <div className='space-y-4'>
            <DownloadTile href={props.tax.sample_schedule_c_form_1040} download={`${props.tax.period_name}_1040_schedule_C_sample`} text={`${props.tax.period_name} · Sample Schedule C (Form 1040)`} />
            <InfoBox url='../gains-losses/' content='If you’ve sold, traded, or disposed of any crypto, you also need to file Form 8949 to report gains/losses from the transaction(s)'/>
          </div>
        </div>

        <SectionBreak />

        <div className='space-y-6'>
          <Heading text="Schedule 1 and Form 1040" />
          <div className='space-y-4'>
            <p>Without a full picture of your 2021 taxes, we cannot accurately fill out Schedule 1 and Form 1040 for you.</p>
            <p>The sample forms below illustrate how to fill out those forms if mining rewards were your sole income. Use them for reference only.</p>
          </div>
          <div className='space-y-4'>
            <DownloadTile href={props.tax.sample_schedule_1_form_1040} download={`${props.tax.period_name}_transaction_report`} text={`${props.tax.period_name} · Sample Schedule 1 (Form 1040)`} />
            <DownloadTile href={props.tax.sample_form_1040} download={`${props.tax.period_name}_1040_sample`} text={`${props.tax.period_name} · Sample Form 1040`} />
          </div>
        </div>
        
        <div className='space-y-6'>
          <Button button_type={button_type.underline} button_size={button_size.small} onClick={handleNavigateToTaxes}>Back to Taxes</Button>
          <TaxDisclosure />
        </div>
        
      </div>
    </div>
  )
}

function DownloadTile(props) {
  return(
    <a href={props.href} download={props.download} className='flex justify-between items-center rounded-xl p-4 text-sm font-semibold ring-1 ring-content-tertiary hover:ring-2 hover:ring-content-primary hover:bg-gray-50  transition-all'><span>{props.text}</span><GetAppIcon fontSize="small" color="primary"/></a>
  )
}