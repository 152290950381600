import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined'
import GetAppIcon from '@material-ui/icons/GetApp'
import currencyFormatter from 'currency-formatter'
import React from 'react'
import FAQBlockItem from '../FaqScreen'
import HairlineBreak from '../common/hairlinebreak'
import Heading from '../common/heading'
import InfoBox from '../common/infobox'
import SectionBreak from '../common/sectionbreak'
import TaxDisclosure from './tax-disclosure'

export default function TaxHome(props) {

  return (
    <div>
      <div className="main_griffin_container space-y-8">
        <h1>Taxes</h1>
        <div className='space-y-10'>
          <div className='space-y-6'>
            <InfoBox url='../tax/as-business/' content='Subscriptions can be reported as business expenses on Schedule C (Form 1040).' />
            <div className='rounded-xl border border-content-tertiary p-6 space-y-6'>
              <Heading text="Summary" />
              <div className='space-y-4'>
                <div className='flex justify-between'>
                  <div>Mining rewards</div>
                  <div>${currencyFormatter.format(props.tax.mining_rewards, 'USD')}</div>
                </div>
                <div className='flex justify-between'>
                  <div>Subscriptions</div>
                  <div>(${currencyFormatter.format(props.tax.subscriptions, 'USD')})</div>
                </div>
                <HairlineBreak />
                <div className='flex justify-between font-semibold'>
                  <div>Estimated net profit (loss)</div>
                  <div>(${currencyFormatter.format(props.tax.subscriptions - props.tax.mining_rewards, 'USD')})</div>
                </div>
              </div>
            </div>
          </div>
          <SectionBreak />
          <div className='space-y-4'>
            <Heading text="Tax Reports" />
            <p>These reports can help you understand and file your taxes. We don't share them with the IRS.</p>
            <div className='divide-y divide-content-quaternary'>
              <a href={props.tax.raw_transaction_report} download={`${props.tax.period_name}_transaction_report`} className='py-6 flex justify-between font-semibold hover:text-brand'>{props.tax.period_name} · Raw transactions report <GetAppIcon fontSize="small" color="primary" /></a>
              <a href={props.tax.sample_schedule_c_form_1040} download={`${props.tax.period_name}_1040_schedule_C_sample`} className='py-6 flex justify-between font-semibold hover:text-brand'>{props.tax.period_name} · Sample Schedule C (Form 1040) <GetAppIcon fontSize="small" color="primary" /></a>
              <a href={props.tax.sample_schedule_1_form_1040} download={`${props.tax.period_name}_1040_schedule_1_sample`} className='py-6 flex justify-between font-semibold hover:text-brand'>{props.tax.period_name} · Sample Schedule 1 (Form 1040) <GetAppIcon fontSize="small" color="primary" /></a>
              <a href={props.tax.sample_form_1040} download={`${props.tax.period_name}_1040_sample`} className='py-6 flex justify-between font-semibold hover:text-brand'>{props.tax.period_name} · Sample Form 1040 <GetAppIcon fontSize="small" color="primary" /></a>
            </div>
          </div>
          <SectionBreak />
          <div className='space-y-6'>
            <Heading text="Resources" />
            <div className='space-y-4'>
              <ResourceTile url='../tax/as-business/' text='Mining crypto as a business' />
              <ResourceTile url='../tax/gains-losses/' text='Report crypto gains/losses' />
              <ResourceTile url='../tax/cointracker/' text='CoinTracker' />
            </div>
          </div>
          <SectionBreak />
          <div>
            <Heading text="FAQs" />
            <div className="divide-y divide-content-quaternary">
              <FAQBlockItem
                small
                question="How are mining rewards taxed?"
                answers={['The Internal Revenue Service (IRS) considers crypto received from mining as ordinary income. Its fair market value at the time you received it will be added to your other taxable income received throughout the year.', 'If you’ve sold, traded, or disposed of any crypto, they will be taxed as capital gains or losses.']} />
              <FAQBlockItem
                small
                question="Do I need any crypto tax filing software?"
                answers={['If you only received mining rewards and/or sold some of it, you likely don’t need crypto tax software.', 'With the provided documents and guides here, your CPA or any tax software supporting small business report should be enough.']} />
              <FAQBlockItem
                small
                question="Does Griffin report my activity to the IRS?"
                answers={['Griffin is not required to report your mining activities to the IRS at this time. We only provide the raw transactions report and sample forms to help you with filing your taxes.']} />
            </div>
          </div>
          <TaxDisclosure />
        </div>
      </div>
    </div>
  )
}

function ResourceTile(props) {
  return(
    <a href={props.url} className='flex space-x-2 items-center rounded-xl p-4 font-semibold ring-1 ring-content-tertiary hover:ring-2 hover:ring-content-primary hover:bg-gray-50 transition-all'><DescriptionOutlinedIcon fontSize="small"/><span>{props.text}</span></a>
  )
}
