import React from 'react'
import { Link } from 'react-router-dom'
import * as rdd from 'react-device-detect';
import QRCode from "react-qr-code";
import ScrollToTop from './common/scrollToTop';


export default function LandingScreen() {
  return (
    <ScrollToTop>
      <div className="bg-purple-darker pt-10 lg:pt-20 pb-8 min-h-screen overflow-hidden">
        <div className="container px-8 xl:px-24">
          <div className="flex justify-between space-x-0 lg:space-x-20 -space-y-16 md:space-y-0 flex-col lg:flex-row relative">
            <div className="flex flex-col justify-center items-center lg:items-start space-y-4 w-full text-center lg:w-1/2 xl:4/12 lg:text-left max-w-screen-lg z-10">
              <img alt="Griffin logo" className="w-8 lg:w-10 pb-2 lg:pb-6" src="https://res.cloudinary.com/dqafyoayf/image/upload/v1629697839/Griffin_Logomark_-_Red_kuseg9.svg"/>
              <p className="text-3xl lg:text-5xl text-content-primaryOnDark font-inter font-black">The easy way to earn, store, and send crypto</p>
              <p className="font-sans text-content-primaryOnDark max-w-prose">Griffin plans earn you mining rewards with a guarantee of 10% profit. You get paid every two weeks and can cash out anytime.</p>
              
              <div className="pt-10 pb-10 lg:pb-0">
                {rdd.isIOS 
                  ? <Link to="/download"><img src="https://res.cloudinary.com/dqafyoayf/image/upload/v1659223349/Apple_xrgnre.svg" alt="Griffin ios app link" /></Link>
                  : rdd.isAndroid 
                    ? <Link to="/download"><img src="https://res.cloudinary.com/dqafyoayf/image/upload/v1659223435/Google_qpglep.svg" alt="Griffin ios app link" /></Link>
                    : <div className="flex flex-row flex-wrap space-x-4 md:space-x-8">
                        <div className="w-32 h-32 bg-white rounded-xl p-2"><QRCode size={112} value="https://www.griffincrypto.com/download"/></div>
                        <div className="w-40 pt-3">
                          <p className="text-content-primaryOnDark text-3xl font-black pb-2">←</p>                        
                          <p className="text-aqua-medium text-lg font-black">Scan to download Griffin app</p>
                        </div>
                      </div>
                }   
              </div>         
            
              <div className="block -mx-20 lg:hidden">
                <img src="https://res.cloudinary.com/dqafyoayf/image/upload/v1659221910/v2-home-phone-mobile_x033bf.svg" alt="Griffin app dashboard screenshot" />
              </div>

              <div className="items-center lg:items-start">
                <LandingNavigation />
              </div>
            </div>
            <div className="hidden w-1/2 xl:w-8/12 lg:block">
              <div className="relative transform translate-y-24 text-center">
                <img className="w-full" src="https://res.cloudinary.com/dqafyoayf/image/upload/v1659060892/v2-home-bgr-desktop_jfihzk.svg" alt="Griffin app dashboard screenshot" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ScrollToTop>
  )
}

function LandingNavigation() {
  return (
    <div className="mt-10 space-y-4">
      <p className="text-content-secondaryOnDark text-sm">© 2022 Griffin Labs, Inc.</p>
      <div className="flex flex-row flex-wrap space-x-4 md:space-x-8">
        <Link to="/about"><p className="text-content-primaryOnDark">About us</p></Link>
        <Link to="/terms"><p className="text-content-primaryOnDark">Legal</p></Link>
        <Link to="/faq"><p className="text-content-primaryOnDark">FAQs</p></Link>
        <Link to="/login"><p className="text-content-primaryOnDark">Login (legacy)</p></Link>
      </div>
    </div>
  )
}