import { white } from 'jest-matcher-utils/node_modules/chalk';
import React from 'react';
import Select, { components } from 'react-select';
import './inputfield.scss';

export default class InputField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {isActive: this.props.value !== undefined && this.props.value !== ''};
    this.handleTextChange = this.handleTextChange.bind(this);
  }

  handleTextChange(event) {
    this.setState({isActive: event.target.value !== undefined && event.target.value !== ''});
    this.props.onChange(event);
  }

  render() {
    return(
      <div className="input_field">
        <input type={this.props.type} value={this.props.value} id={this.props.name} onChange={this.handleTextChange} autoComplete={this.disable_autocomplete ? 'off' : 'on'} />
        <label htmlFor={this.props.name} className={this.state.isActive ? "active" : ""}>{this.props.label}</label>
      </div>
    )
  }
}

const { ValueContainer, Placeholder } = components;

const CustomSelectContainer = ({ children, ...props }) => {
  return (
    <ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, child =>
        child && child.type !== Placeholder ? child : null
      )}
    </ValueContainer>
  );
};

export class SelectField extends React.Component {
  constructor(props) {
    super(props);
    this.handleSelectionChange = this.handleSelectionChange.bind(this);
  }

  handleSelectionChange(selectedOption) {
    this.props.onChange(selectedOption);
  }

  render() {
    return (
      <Select
        name={this.props.name}
        defaultValue={this.props.defaultValue}
        options={this.props.options}
        components={{
          ValueContainer: CustomSelectContainer
        }}
        onChange={this.handleSelectionChange}
        placeholder={this.props.label}
        getOptionLabel={this.props.getOptionLabel}
        styles={{
          control: (provided) => ({
            ...provided,
            height: 72,
            border: '1px solid rgba(11, 34, 54, 0.1)',
            padding: '0 14px 0 14px',
            borderRadius: 12,
            cursor: 'pointer',
          }),
          valueContainer: (provided, state) => ({
            ...provided,
            overflow: "visible",
            top: state.hasValue || state.selectProps.inputValue ? 10 : 0,
            fontFamily: 'Inter',
            fontSize: 16,
            fontWeight: 500,
            color: '#281D35',
          }),
          placeholder: (provided, state) => ({
            ...provided,
            position: "absolute",
            top: state.hasValue || state.selectProps.inputValue ? -10 : 16,
            fontFamily: 'Inter',
            fontSize: (state.hasValue || state.selectProps.inputValue) ? 12 : 16,
            lineHeight: 19,
            transition: 'all 0.2s ease-out',
            color: '#7C7282',
            fontWeight: 400,
          }),
          indicatorSeparator: () => ({
            backgroundColor: white,
          }),
          menu: (provided) => ({
            ...provided,
            borderRadius: 12,
            overflow: 'hidden',
          }),
          option: (provided) => ({
            ...provided,
            height: 72,
            padding: '22px 25px 0 25px',
            fontFamily: 'Inter',
            fontSize: 16,
            fontWeight: 500,
            color: '#281D35',
            cursor: 'pointer',
          })
        }}
      />
    );
  }
}