import React from 'react'

export default function InfoDataRow(props) {
  return(
    <div>
      { props.dotted_row
        ? <div className="flex py-3">
            <p className="text-sm font-semibold pr-2">{props.label}</p>
            <div className="flex-grow border-b border-dotted border-gray-200 mb-1"></div>
            <p className="text-sm text-right pl-2">{props.info_data}</p>
          </div>
        : <div className="flex py-4 border-t border-gray-100">
            <p className="flex-grow text-sm">{props.label}</p>
            <p className="text-sm text-right text-content-secondary">{props.info_data}</p>
          </div>
      }
    </div>
  )
}