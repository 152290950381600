import React from 'react';
import { NavLink } from 'react-router-dom';
import { useHistory } from 'react-router-dom'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import './topbar.scss';


export function Topbar() {
  return (
    <div id="topbar">
      <NavLink to="/"><img alt="Griffin logo" id="topbar_logo" src="https://res.cloudinary.com/dqafyoayf/image/upload/v1629697839/Griffin_Logomark_-_Red_kuseg9.svg"/></NavLink>
      <ul className="topbar_navlink invisible md:visible">
        <li><NavLink to="/dashboard">Earning</NavLink></li>
        <li><NavLink to="/plan">Plan</NavLink></li>
        <li><NavLink to="/account">Account</NavLink></li>
      </ul>
    </div>
  )
}

export function TopbarBackNavigation() {
  const history = useHistory();

  return (
    <div id="topbar">
      <button className="p-6 -mb-2 -ml-6" onClick={() => history.goBack()}>
        <ArrowBackIcon color="primary"/>
      </button>
    </div>
  )
}
