export function normalizeSsn(inputValue) {
  if (!inputValue) return inputValue;
  const currentValue = inputValue.replace(/[^\d]/g, '');
  const cvLength = currentValue.length;
  
  if (cvLength < 4) return currentValue;
  if (cvLength < 6) return `${currentValue.slice(0, 3)}-${currentValue.slice(3)}`;
  return `${currentValue.slice(0, 3)}-${currentValue.slice(3, 5)}-${currentValue.slice(5, 9)}`;
}

export function isValidSsn(value) {
  const ssn = value.replace(/[^\d]/g, '');
  return ssn.length === 9;
}

export const ROUND_USD_CURRENCY_FORMAT = {
  "symbol": "$",
  "thousand": ",",
  "precision": 0
}

export function roundedDecimalToPlaces(value, decimalPlaces=8) {
  const numberAmount = Number(value);
  return Math.round(numberAmount * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces);
}