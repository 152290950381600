import React from "react"
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'

export default function InfoBox(props) {
  return (
    <a href={props.url} className="block rounded-xl ring-1 ring-content-tertiary hover:ring-2 hover:ring-content-primary hover:bg-gray-50 transition-all">
      <div className="p-4 text-sm flex space-x-4">
        <div className="text-base flex pt-0.5">
          <InfoOutlinedIcon fontSize='inherit'/>
        </div>
        <div className='space-y-2'>
          <div>{props.content}</div>
          <div className='underline font-semibold'>Learn more</div>
        </div>
      </div>
    </a>
  )
}