import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import {
  BrowserRouter as Router, Redirect, Route, Switch
} from "react-router-dom";
import API from './api-service';
import { UserContext } from './context';
import LandingScreen from './components/LandingScreen';
import FaqScreen from './components/FaqScreen';
import AboutScreen from './components/AboutScreen';
import TermsScreen, { MobileTermsScreen, TermsReferralScreen } from './components/TermsScreen';
import PrivacyScreen, { MobilePrivacyScreen } from './components/PrivacyScreen';
import AuthLoginScreen from './components/AuthLoginScreen';
import AuthForgotPasswordScreen from './components/AuthForgotPasswordScreen';
import AuthResetPasswordScreen from './components/AuthResetPasswordScreen';
import TaxScreen from './components/TaxScreen';
import EarningScreen from './components/EarningScreen';
import PlanListScreen from './components/PlanListScreen';
import AccountScreen from './components/AccountScreen';
import DownloadScreen from './components/DownloadScreen';
// import * as rdd from 'react-device-detect';


export default function App() {
  const [isRendered, setIsRendered] = useState(false);
  const [user, setUser] = useState(null);
  const [cookies, removeCookie] = useCookies(['griffin-token']);

  // const IOS_APP_LINK = "https://apple.com";
  // const ANDROID_APP_LINK = "https://google.com";

  useEffect(() => {
    async function updateUserInfo(){
      let response = await API.getUserInfo(cookies['griffin-token'])
      if (response.status === 401){
        removeCookie('griffin-token');
      } else if (response.ok){
        let userData = await response.json()
        setUser(userData.data);
      }
      setIsRendered(true);
    }

    const isLoggedIn = cookies['griffin-token'] !== undefined && cookies['griffin-token'] !== 'undefined';
    if (isLoggedIn) {
      updateUserInfo()
    } else {
      setUser(null);
      setIsRendered(true);
    }
  }, [cookies]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <UserContext.Provider value={[user, setUser]}>
      <Router>
        {isRendered && 
          <div className="m-auto w-full">
            <Switch>
              <Route path="/welcome"><LandingScreen /></Route>
              <Route path="/terms"><TermsScreen /></Route>
              <Route path="/terms-mobile"><MobileTermsScreen /></Route>
              <Route path="/terms-referral"><TermsReferralScreen /></Route>
              <Route path="/privacy"><PrivacyScreen /></Route>
              <Route path="/privacy-mobile"><MobilePrivacyScreen /></Route>
              <Route path="/faq"><FaqScreen /></Route>
              <Route path="/about"><AboutScreen /></Route>
              <Route path="/download">
                <DownloadScreen />
                {/* {rdd.isIOS 
                    ? <Redirect to={IOS_APP_LINK} />
                    : rdd.isAndroid 
                      ? <Redirect to={ANDROID_APP_LINK} />
                      : <Redirect to="/welcome" />
                }    */}
              </Route>
              <Route path="/login">{user ? <Redirect to="/dashboard" /> : <AuthLoginScreen />}</Route>
              <Route path="/forgot-password"><AuthForgotPasswordScreen /></Route>
              <Route path="/reset-password"><AuthResetPasswordScreen /></Route>
              <Route path="/dashboard">{user ? <EarningScreen /> : <Redirect to="/login" />}</Route>
              <Route path="/plan">{user ? <PlanListScreen /> : <Redirect to="/login" />}</Route>
              <Route path="/account">{user ? <AccountScreen /> : <Redirect to="/login" />}</Route>
              <Route path="/tax">{user ? <TaxScreen /> : <Redirect to="/login" /> }</Route>
              <Route exact path="/">{user ? <Redirect to="/dashboard" /> : <LandingScreen />}</Route>
            </Switch>
          </div>
        }
      </Router>
    </UserContext.Provider>
  );
}
