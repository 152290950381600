import React, { useState, useContext } from 'react';
import { useCookies } from 'react-cookie'
import { useHistory } from 'react-router';
import ModalView from '../common/modal';
import FieldRow from '../common/fieldrow';
import { UserContext } from '../../context';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet'
import EmailIcon from '@material-ui/icons/Email'
import DescriptionIcon from '@material-ui/icons/Description'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import GavelIcon from '@material-ui/icons/Gavel'

export default function AccountDashboard(props) {
  const [logoutModalOpenState, setLogoutModalOpenState] = useState(false);
  const [cookies, removeCookie] = useCookies(['griffin-token']); // eslint-disable-line no-unused-vars
  const [user] = useContext(UserContext);
  const history = useHistory();
  const latest_tax = user?.latest_tax
  return(
    <div>
      <div className="main_griffin_container pb-20">
          <div className="text-2xl font-semibold pb-4">Account</div>        
          <div className="divide-y divide-content-quaternary">
          <FieldRow title="Email address" subtitle={user['email']}><EmailIcon fontSize="small"/></FieldRow>
          <FieldRow title="Mining wallets" subtitle={`${'eth_wallet_address' in user ? user['eth_wallet_address'] : null}`} onClick={() => history.push('/account/wallet')}><AccountBalanceWalletIcon fontSize="small"/></FieldRow>
          {latest_tax && <FieldRow title="Tax" onClick={() => history.push('/tax')}><DescriptionIcon fontSize="small"/></FieldRow>}
          <FieldRow title="Documents & Invoices" onClick={() => history.push('/account/document')}><DescriptionIcon fontSize="small"/></FieldRow>
          <FieldRow title="Terms & Privacy" onClick={() => window.open('/terms', '_blank')}><GavelIcon fontSize="small"/></FieldRow>
          <FieldRow title="Log out" onClick={() => setLogoutModalOpenState(true)}><ExitToAppIcon fontSize="small"/></FieldRow>
        </div>
      </div>
      <ModalView
        open={logoutModalOpenState}
        title="Log out"
        text="Are you sure you want to log out of your Griffin account now?"
        primaryAction="Log out"
        onPrimaryActionClick={() => {
          removeCookie('griffin-token')
        }}
        secondaryAction="Dismiss"
        onSecondaryActionClick={() => setLogoutModalOpenState(false)}
        onDismissClick={() => setLogoutModalOpenState(false)}/>
    </div>
  )
}
