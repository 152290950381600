import React from 'react';


export default function SectionBreak() {
  return (
    <div className="h-2 relative">
      <div className="bg-gray-100 md:bg-transparent w-screen h-full left-1/2 transform -translate-x-1/2 absolute flex justify-center space-x-3">
        <div className="bg-transparent md:bg-gray-300 h-1 w-1 rounded-full"></div>
        <div className="bg-transparent md:bg-gray-300 h-1 w-1 rounded-full"></div>
        <div className="bg-transparent md:bg-gray-300 h-1 w-1 rounded-full"></div>
      </div>
    </div>
  )
}