import React, { useEffect, useState } from 'react'
import {
  Switch,
  Route,
  useRouteMatch,
  withRouter
} from "react-router-dom"
import { useCookies } from 'react-cookie'
import { TopbarBackNavigation } from './common/topbar'
import TaxHome from './tax/tax-home'
import TaxAsBusiness from './tax/tax-as-business'
import TaxGainsLosses from './tax/tax-gains-losses'
import TaxCointracker from './tax/tax-cointracker'
import API from '../api-service'
import ScrollToTop from './common/scrollToTop'

function TaxScreen(props) {
  let { path } = useRouteMatch();
  const [cookies, removeCookie] = useCookies(['griffin-token']);
  const [tax, setTax] = useState({})
  useEffect(
    () => {
      if (cookies['griffin-token'] === undefined || cookies['griffin-token'] === 'undefined') return;

      API.getListTax(cookies['griffin-token']).then(response => {
        if (response.status === 401) {
          removeCookie('griffin-token');
        } else if (response.ok) {
          response.json().then(list_tax => {
            setTax(list_tax.data ? list_tax.data[0] : {});
          });
        }
      })
    }
    , [cookies, removeCookie])

  return (
    <div>
      <TopbarBackNavigation />
      {tax &&
        <ScrollToTop>
          <Switch>
            <Route exact path={path}>
              <TaxHome tax={tax}/>
            </Route>
            <Route path={`${path}/as-business`}>
              <TaxAsBusiness tax={tax}/>
            </Route>
            <Route path={`${path}/gains-losses`}>
              <TaxGainsLosses tax={tax}/>
            </Route>
            <Route path={`${path}/cointracker`}>
              <TaxCointracker tax={tax}/>
            </Route>
          </Switch>
        </ScrollToTop>
      }
    </div>
  )
}

export default withRouter(TaxScreen);
