import React from 'react';
import {
  Switch,
  Route,
  useRouteMatch,
  withRouter
} from "react-router-dom";
import { Topbar } from './common/topbar'
import TabBar, { tab_bar_value } from './common/tabbar';
import AccountPlansPage, { PlanDetailPage } from './plan/plan'

function SubscriptionListScreen(props) {
  let { path } = useRouteMatch();

  return(
    <div>
      <Switch>
        <Route exact path={path}>
          <Topbar />
          <AccountPlansPage />
          <TabBar tab_bar_value={tab_bar_value.PLAN} />
        </Route>
        <Route path={`${path}/:subscription_id`} component={PlanDetailPage} />
      </Switch>
    </div>
  )
}


export default withRouter(SubscriptionListScreen);