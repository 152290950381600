import React from 'react';
import './fieldrow.scss'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'

export default function FieldRow(props) {
  return(
    <div className={`FieldRow ${props.onClick ? 'Clickable' : ''}`} onClick={props.onClick}>
      <div className="FieldIcon">
        {props.children}
      </div>
      <div className="flex-grow">
        <p className={`${props.onClick && 'FieldRowClickableTitle'}`}>{props.title}</p>
        {props.subtitle && <p className={`${props.subtitleClasses ? props.subtitleClasses : 'FieldRowSubtitle'}`}>{props.subtitle}</p>}
      </div>
      {props.onClick &&
        <div className="text-content-secondary">
          <NavigateNextIcon />
        </div>
      }
    </div>
  )
}

export function BasicFieldRow(props) {
  return(
    <div className={`FieldRow ${props.onClick ? 'Clickable' : ''}`} onClick={props.onClick}>
      {props.children}
    </div>
  )
}