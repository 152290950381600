import React from 'react'
import { Link } from 'react-router-dom';
import { PreSignInFooter } from './common/footer';
import ScrollToTop from './common/scrollToTop';

export default function FaqScreen() {
  return (
    <ScrollToTop>
      <div className="container px-8 xl:px-24 py-10 lg:py-20">
        <Link to="/"><img alt="Griffin logo" className="w-8 lg:w-10 pb-10" src="https://res.cloudinary.com/dqafyoayf/image/upload/v1629697839/Griffin_Logomark_-_Red_kuseg9.svg"/></Link>
        <div className="text-2xl font-semibold py-4">FAQs</div>

        <div className="divide-y divide-content-quaternary">
          <FAQBlockItem
            question="What is Griffin?"
            answers={['Griffin is a cloud mining service provider that offers mining plans with a guarantee of 10% profit. You get paid every two weeks and can cash out anytime.']} />
          <FAQBlockItem
            question="What does guarantee of profit mean?"
            answers={['Your mining plan comes with our guarantee of profit. It stays active for at least a calendar year or until your annual fiat earnings meet or exceed 110% of the cost. We can’t think of any other investments with unlimited upside and zero downside risk.']} />
          <FAQBlockItem
            question="How does Griffin calculate profit?"
            answers={['We use the fiat value at the time of payouts. For example, if you earn 0.1ETH at $300 for your first payout and 0.1ETH at $400 for your second payout, your fiat earnings are $700. The fiat earnings number displayed in your dashboard reflects this calculation.']} />
          <FAQBlockItem
            question="How are payouts made and in what amount?"
            answers={['Payouts will be made by midnight every other Friday. Since mining rewards amount is varied depending on market conditions, it’s hard to give a definite estimation. You can check your upcoming payout amount anytime on the Griffin dashboard.']} />
          <FAQBlockItem
            question="Besides the plan cost, what other fees does Griffin charge?"
            answers={['Zero. There are no electricity or maintenance fees.']} />
          <FAQBlockItem
            question="Mining seems very profitable, why isn't Griffin mining itself?"
            answers={['We are indeed. Not only do we mine for ourselves using the identical equipment offered in our service, but we manage our earnings through the same software you have access to.']} />
          <FAQBlockItem
            question="How can mining hedge against volatility?"
            answers={['Mining is a great strategy to hedge your crypto portfolio against volatility. During periods of volatility, the increase in the number of transactions will proportionally raise mining’s yield. In a downturn, while the fiat value per crypto decreases, its total is made up by the increased amount of crypto mined. On the other hand, a rally shoots up the total fiat value due to the increase of both fiat value per crypto and amount of crypto mined.']} />
        </div>
        <PreSignInFooter />
      </div>
    </ScrollToTop>
  )
}

export function FAQBlockItem(props) {
  var answers = props.answers;
  const answerListItems = answers.map((answer) =>
    <p className="leading-7" key={answer}>{answer}</p>
  );
  return (
    <div className="py-8 space-y-4">
      <h5 className={props.small ? 'text-base font-semibold' : 'text-xl font-medium'}>{props.question}</h5>
      <div className="space-y-4">{answerListItems}</div>
    </div>
  )
}