import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import ScrollToTop from './common/scrollToTop';


export default function DownloadScreen() {
  const timerComponents = [];

  const calculateTimeLeft = () => {
    const difference = +new Date(`2022-11-1`) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.max(0, Math.floor(difference / (1000 * 60 * 60 * 24))),
        hours: Math.max(0, Math.floor((difference / (1000 * 60 * 60)) % 24)),
        minutes: Math.max(0, Math.floor((difference / 1000 / 60) % 60)),
        seconds: Math.max(0, Math.floor((difference / 1000) % 60)),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  });

  Object.keys(timeLeft).forEach((interval) => {
    const remainingTime = String(timeLeft[interval]).padStart(2, '0')

    timerComponents.push(
      <div className="flex flex-row align-top" key={interval}>
        <div>
          <div className="mb-2">
            <span className="text-2xl lg:text-5xl font-semibold text-aqua-medium bg-opacity-10 bg-aqua-light p-2 rounded-md mr-1">{remainingTime[0]}</span>
            <span className="text-2xl lg:text-5xl font-semibold text-aqua-medium bg-opacity-10 bg-aqua-light p-2 rounded-md">{remainingTime[1]}</span>
          </div>
          <span className="text-sm text-content-primaryOnDark">{interval}</span>
        </div>
        {interval !== 'seconds' && <span className="mt-0 text-2xl lg:text-5xl font-semibold text-aqua-light opacity-10 px-2">:</span>}
      </div>
    );
  });
  
  return (
    <ScrollToTop>
      <div className="bg-purple-darker pt-10 lg:pt-20 pb-8 min-h-screen overflow-hidden">
        <div className="container px-8 xl:px-24">
          <div className="flex justify-between space-x-0 lg:space-x-20 -space-y-16 md:space-y-0 flex-col lg:flex-row relative">
            <div className="flex flex-col justify-center items-center space-y-4 w-full text-center max-w-screen-lg z-10">
              <img alt="Griffin logo" className="w-8 lg:w-10 pb-2" src="https://res.cloudinary.com/dqafyoayf/image/upload/v1629697839/Griffin_Logomark_-_Red_kuseg9.svg"/>
              <p className="text-3xl lg:text-5xl text-content-primaryOnDark font-inter font-black">We are launching in</p>
              <div className="flex flex-row py-8">
                {timerComponents}
              </div>
              <p className="font-sans text-content-primaryOnDark max-w-prose">Saturday, October 8th, 2022.</p>
            </div>
          </div>
        </div>
        <div className="absolute bottom-4 text-center w-screen">
          <LandingNavigation />
        </div>
      </div>
    </ScrollToTop>
  )
}

function LandingNavigation() {
  return (
    <div className="mt-10 space-y-4">
      <p className="text-content-secondaryOnDark text-sm">© 2022 Griffin Labs, Inc.</p>
      <div className="flex flex-row flex-wrap justify-center space-x-4 md:space-x-8">
        <Link to="/about"><p className="text-content-primaryOnDark">About us</p></Link>
        <Link to="/terms"><p className="text-content-primaryOnDark">Legal</p></Link>
        <Link to="/faq"><p className="text-content-primaryOnDark">FAQs</p></Link>
        <Link to="/login"><p className="text-content-primaryOnDark">Login (legacy)</p></Link>
      </div>
    </div>
  )
}