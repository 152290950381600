import React from 'react'
import './modal.scss'
import Button, { button_type, button_width } from './button'

export default function ModalView(props) {
  return (
    <div className={`ModalContainer ${props.open && 'open'}`} onClick={props.onDismissClick}>
      <div className="Modal" onClick={(e) => {e.stopPropagation()}}>
        <div className="main_griffin_container">
          {props.title && <p className="text-2xl pb-6">{props.title}</p>}
          {props.text && <p>{props.text}</p>}
          {props.children}
          <div className="py-8 space-y-4 md:hidden">
            {props.primaryAction &&
              <Button button_type={button_type.dark} button_width={button_width.full_screen} disabled={props.disabled ? true : false} onClick={props.onPrimaryActionClick}>{props.primaryAction}</Button>
            }
            {props.secondaryAction &&
                <Button button_type={button_type.hairline} button_width={button_width.full_screen} disabled={props.disabled ? true : false} onClick={props.onSecondaryActionClick}>{props.secondaryAction}</Button>
              }
          </div>
          
          {(props.primaryAction || props.secondaryAction) && 
            <div className="pt-10 pb-6 float-right hidden md:flex">
              {props.secondaryAction &&
                <Button button_type={button_type.hairline} disabled={props.disabled ? true : false} onClick={props.onSecondaryActionClick}>{props.secondaryAction}</Button>
              }
              {props.primaryAction &&
                <div className="ml-3">
                  <Button button_type={button_type.dark} disabled={props.disabled ? true : false} onClick={props.onPrimaryActionClick}>{props.primaryAction}</Button>
                </div>
              }
            </div>
          }
        </div>
      </div>
    </div>
  )
}