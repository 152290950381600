import React from 'react';
import {
  Switch,
  Route,
  useRouteMatch,
  withRouter,
} from "react-router-dom";
import { Topbar, TopbarBackNavigation } from './common/topbar'
import TabBar, { tab_bar_value } from './common/tabbar';
import AccountDashboard from './account/account-dashboard'
import AccountMiningWalletsPage, {CreateNewWalletPage} from './account/wallet'
import { AccountDocumentsPage, ReceiptDetailPage } from './account/receipt';

function AccountScreen(props) {
  let { path } = useRouteMatch();
  return(
    <div>
      <Switch>
        <Route exact path={path}>
          <Topbar/>
          <AccountDashboard />
          <TabBar tab_bar_value={tab_bar_value.PROFILE} />
        </Route>
        <Route exact path={`${path}/wallet`}>
          <TopbarBackNavigation />
          <AccountMiningWalletsPage />
        </Route>
        <Route path={`${path}/wallet/create`}>
          <TopbarBackNavigation />
          <CreateNewWalletPage is_new_wallet_primary={false}/>
        </Route>
        <Route exact path={`${path}/document`}>
          <TopbarBackNavigation />
          <AccountDocumentsPage />
        </Route>
        <Route path={`${path}/receipt/:receipt_id`} component={ReceiptDetailPage} />
      </Switch>
    </div>
  )
}

export default withRouter(AccountScreen);