export function GetRequestParam(param, defaultValue = false) {
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i=0;i<vars.length;i++) {
    var pair = vars[i].split("=");
    if(pair[0] === param) {
      return pair[1];
    }
  }
  return defaultValue;
}

export function GetResponseErrorMessage(err) {
  if (err.constructor === Object) {
    return GetResponseErrorMessage(Object.values(err)[0]);
  } else if (err instanceof Array) {
    return GetResponseErrorMessage(err[0]);
  }

  return err;
}