export function ValidateEmail(email) {
  var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
  return pattern.test(email);
}

export function ValidatePassword(password) {
  var pattern = new RegExp(/^((?!.*[\s])(?=.*[a-zA-Z])(?=.*\d).{8,})$/i);
  return pattern.test(password);
}

export function ValidatePhoneNumber(phoneNumber){
  var pattern = new RegExp(/^[0-9\-+]{9,15}$/);
  return pattern.test(phoneNumber);
}